import React, { useState } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import { createMember } from "service/notifactionService";
import { showAlert } from "components/Common/AlertMessage";
import { Input, Label } from "reactstrap";

// Added by Parth Roka 05/12/2023
const UsernameCreateNotification = (props) => {

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const [UsernameValidation, setUsernameValidation] = useState(false);
  const [EmailValidation, setEmailValidation] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function handleEmail(e) {
    let value = e.target.value
    if (value) {
      if (!isValidEmail(value)) {
        setEmailValidation(true);
      }
      else {
        setEmailValidation(false);
      }
    }
    else {
      setEmailValidation(true);

    }
  }

  function handleUsername(e) {
    let value = e.target.value
    if (value) {
      setUsernameValidation(false);
    }
    else {
      setUsernameValidation(true);
    }
  }

  // main function 
  const handleSubmit = (e) => {
    if (email) {
      if (!isValidEmail(email)) {
        setEmailError("please enter valid email");
      }
      else {
        setEmailError("")
      }
    }
    else {
      setEmailError("please enter email");
    }

    if (username) {
      setUsernameError("");
    }
    else {
      setUsernameError("please enter username");
    }

    if (EmailValidation == false && UsernameValidation == false && email && username) {
      getNotifcation()
    }
  };

  // for create coupon API
  const getNotifcation = async () => {

    let dataBody = {
      "email": email,
      "member_name": username
    };

    const res = await createMember(dataBody);
    if (res.code == 200) {
      showAlert("success", "Username Create Successful!");

      setEmail("");
      setUsername("");
      props.customActiveTab("3", false, false, false, true);
    } else {
      showAlert("error", "There Is a Error Try Again");
    }
  };


  return (
    <>

      <div className='row'>
        <div className="col-md-12">
          <Label className="form-label mb-1">Name:</Label>
          <Input
            type="text"
            value={username}
            onChange={(e) => { setUsername(e.target.value) ; handleUsername(e) }}
            placeholder='Name'
          />
          <p className='text-danger'> {usernameError}</p>
        </div>
      </div>

      <div className='row'>
        <div className="col-md-12">
          <Label className="form-label mb-1">Email:</Label>
          <Input
            type="text"
            value={email}
            onChange={(e) => { setEmail(e.target.value); handleEmail(e)}}
            placeholder='Email'
          />
          <p className='text-danger'> {emailError}</p>
        </div>
      </div>

        <div className='row'>
          <div className='col-md-12 text-center'>
            <button className='Coupon-Create-submit-btn' onClick={handleSubmit}>Submit</button>
          </div>
        </div>

    </>
  );
};


UsernameCreateNotification.propTypes = {
  notifaction: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(UsernameCreateNotification);
