import { ORDER_DETAILS } from "./actionTypes";

const initialState = {
  OrdersDetail: null,
  loading: false,
};

const Orders = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_DETAILS:
      state = {
        ...state,
        OrdersDetail: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Orders;
