import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyEmail from "pages/Authentication/VerifyEmail";
import ResetPassword from "pages/Authentication/ResetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Users from "pages/Users";
import Orders from "pages/Orders";
import SearchResults from "pages/SearchResults";
import ContentManager from "pages/ContentManager";
import Notifications from "pages/Notifications";
import Maintenance from "pages/Maintenance";
import CouponNotifications from "pages/Coupon";


import Support from "pages/Support";
import AddUsers from "pages/Users/add-user";
import UserDetails from "pages/Users/user-details";
import OrdersDetails from "pages/Orders/order-details";
import SearchResultDetails from "pages/SearchResults/search-details";
import AddContentManagement from "pages/ContentManager/add-content-management";
import Pages404 from "pages/Utility/pages-404";
import EditContentManagement from "pages/ContentManager/edit-content-manager";
import Services from "pages/Service";
import EditService from "pages/Service/editService";
import UserProfile from "components/Users/profile";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: Users },
  { path: "/profile", component: UserProfile },
  { path: "/users/add-user", component: AddUsers },
  { path: "/users/user-detail/:id", component: UserDetails },
  { path: "/support", component: Support },
  { path: "/order/order-details/:id", component: OrdersDetails },
  { path: "/orders", component: Orders },
  { path: "/notifications", component: Notifications },
  { path: "/maintenance", component: Maintenance },
  { path: "/coupon", component: CouponNotifications },


  
  { path: "/search-results", component: SearchResults },
  { path: "/search-results/details/:id", component: SearchResultDetails },
  { path: "/content-manager", component: ContentManager },
  { path: "/content-manager/add-new", component: AddContentManagement },
  { path: "/content-manager/edit/:id", component: EditContentManagement },
  { path: "/services", component: Services },
  { path: "/services/edit/:id", component: EditService },
  { path: "/logout", component: Logout },
  { path: "/*", component: Pages404 },
];

const notAuthenticatedRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/verify-email", component: VerifyEmail },
  { path: "/reset-password", component: ResetPassword },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

export { authProtectedRoutes, notAuthenticatedRoutes };
