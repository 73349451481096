import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import EditIcon from "./../../assets/icons/edit-icon.svg";
import RemoveICon from "./../../assets/icons/remove-icon.svg";
import { Button, Card, CardBody, Col, Row, Badge } from "reactstrap";
import { getOrders as onGetOrders, typeUser } from "store/actions";
//redux
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import UserModal from "pages/Users/userModal";
import DeleteModal from "components/Common/UserSuspendModal";
import { getUserAllData, deleteUser } from "../../service/userService";
import { showAlert } from "components/Common/AlertMessage";
import moment from "moment";
const RecentRegisteredUser = (props) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setuserData] = useState([]);
  const [userfilter, setUserfilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const dispatch = useDispatch();
  const [saveDelId, setSaveDelUId] = useState(0);

  ///old modal
  const [oldModal, setOldModal] = useState(false);
  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: userData?.length || 1, // userData?.length ||
    custom: true,
  };

  useEffect(() => {
    dispatch(onGetOrders());
  }, [dispatch]);

  const selectRow = {
    mode: "none",
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const toggleViewModal = (row) => {
    setModal1(!modal1);
    setIsEdit(row);
  };
  const disableDiv = {
    pointerEvents: "none",
    opacity: "0.7",
  };
  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "_id",
      text: "USER ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link
          to={
            row.status != "marked_for_deletion" && row.role == "user"
              ? `/users/user-detail/${row._id}`
              : "#"
          }
          //  to="#"
          className="text-body"
        >
          {row._id}
        </Link>
      ),
    },
    {
      dataField: "email",
      text: "PHONE/EMAIL",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mobile16">{row.mobile_phone || ""}</p>
          <p>{row.email || ""}</p>
        </>
      ),
    },
    {
      dataField: "customerType",
      text: "TYPE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>
            {row.role == "admin" ? (
              "Administrator"
            ) : (
              <>
                {row?.customerType == "pre" ? "Pre-customer" : "Post-customer"}
              </>
            )}
          </p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-12 badge-soft-" + row.status}
          color={row.badgeClass}
          pill
        >
          {capitalizeFirstLetter(row.status || "").replace(/_/g, " ")}
        </Badge>
      ),
    },
    {
      dataField: "createdAt",
      text: "CREATED",
      formatter: (cellContent, row) => (
        <p>{moment(row.createdAt).format("MM/DD/YYYY")}</p>
      ),
    },
    {
      dataField: "action",
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div style={row.status == "marked_for_deletion" ? disableDiv : {}}>
          <Link
            to={row.role == "admin" ? "#" : `/users/user-detail/${row?._id}`}
            onClick={() => {
              dispatch(typeUser("dashboard"));
            }}
            className="text-body fw-bold"
          >
            <img src={EditIcon} alt="edit icon" />
          </Link>
          <Button
            type="button"
            className="btn-sm btn-icon btn-rounded"
            onClick={() => {
              onModalOpen(row.first_name);
              setSaveDelUId(row._id);
            }}
          >
            <img src={RemoveICon} alt="remove icon" />
          </Button>
        </div>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ];

  useEffect(async () => {
    setLoading(true);
    const resp = await getUserAllData(userfilter);
    if (resp.code == 200) {
      const data = resp.data.users.filter(
        (user) => user.status == "active" || user.status == "suspended"
      );
      setuserData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);
  const onModalOpen = (val) => {
    const name = val ? val + "!" : "this !";
    setDelModal({ show: true, name: name });
  };
  const onClose = () => {
    setDelModal(false);
  };
  const onDelete = () => {
    setDelModal(false);
    handleDelete();
  };

  const handleDelete = async () => {
    setLoading(true);
    const resp = await deleteUser(saveDelId, "marked_for_deletion");
    if (resp.code == 200) {
      const resp2 = await getUserAllData(userfilter);
      if (resp2.code == 200) {
        let data = resp2.data.users;
        if (Object.keys(userfilter).length == 0) {
          data = data.filter(
            (user) => user.status == "active" || user.status == "suspended"
          );
        }
        setuserData(data);
        showAlert("success", "User Account is Deleted");
        setLoading(false);
      } else {
        showAlert(
          "error",
          resp2.error.data.message || "there Is A error try again"
        );
        setLoading(false);
      }
    } else {
      showAlert(
        "error",
        resp.error.data.message || "there Is A error try again"
      );
      setLoading(false);
    }
  };

  const HandleSetUserData = (data) => {
    setuserData(data);
    setModal1(false);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <UserModal
        isOpen={modal1}
        toggle={toggleViewModal}
        editValue={isEdit}
        userfilter={userfilter}
        HandleSetUserData={HandleSetUserData}
        setLoading={setLoading}
      />
      <DeleteModal
        show={delmodal}
        onDeleteClick={onDelete}
        onCloseClick={onClose}
      />
      <Card>
        <CardBody>
          <div className="mb-4 h4 text-gray card-title d-flex justify-content-between align-items-center">
            RECENTLY REGISTERED USERS
            <Link to="/users" className="view-all-link">
              View all »
            </Link>
          </div>

          {!loading ? (
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={EcommerceOrderColumns(toggle)}
              data={userData || []}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={userData || []}
                  columns={EcommerceOrderColumns(toggle)}
                  bootstrap4
                  search
                >
                  {(toolkitProps) => (
                    <React.Fragment>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="id"
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={
                                "table align-middle table-nowrap table-no-check table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Spinner
                style={{ width: "2rem", height: "2rem" }}
                children={false}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

RecentRegisteredUser.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(RecentRegisteredUser);
