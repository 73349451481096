import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Col, Row } from "reactstrap";
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal";

const UserSmSNotification = (props) => {
  const [notiData, setUserNoti] = useState([]);

  useEffect(() => {
    setUserNoti(props?.notification);
  }, [props]);

  const selectRow = {
    mode: "none",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: notiData?.length, // replace later with size(orders),
    custom: true,
  };

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);

  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "text",
      text: "WHEN",
      sort: true,
      formatter: (cellContent, row) => <>{row.text}</>,
    },
    {
      dataField: "status",
      isDummyField: true,
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div
          className="form-check form-switch d-flex justify-content-end form-switch-lg mb-3"
          onClick={() => {
            props.handleUpdatedCheck(!row.status, row._id, "userSms");
          }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizelg"
            checked={row.status ? true : false}
          />
          <label
            className={
              row.status ? "form-check-label checked" : "form-check-label"
            }
            htmlFor="customSwitchsizelg"
          >
            {row.status == true ? "ON" : "OFF"}
          </label>
        </div>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "_Id",
    },
  ];

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />

      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={EcommerceOrderColumns(toggle)}
        data={notiData || []}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            data={notiData || []}
            columns={EcommerceOrderColumns(toggle)}
            bootstrap4
            search
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={selectRow}
                        classes={
                          "table align-middle table-nowrap tableNotificationNew table-no-check table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="pagination pagination-rounded justify-content-center">
                </div>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  );
};

UserSmSNotification.propTypes = {
  notifaction: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(UserSmSNotification);
