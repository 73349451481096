import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { UPDATE_USER ,GETUSER ,DELETE_USER} from "./actionTypes"
import { updateUserSuccessful, updateUserFailed,getUserData ,userloadingtrue } from "./actions"
import { patchUpdateUser } from "helpers/apis"
import {getAllUser ,deleteuser} from 'helpers/bright_api_helper'


function* updateUser({ payload:  user  }) {
  try {
      yield put(updateUserSuccessful(response))
   
  } catch (error) {
    yield put(updateUserFailed(error))
  }
}
 function* watchUserUpdate() {
  yield takeEvery(UPDATE_USER, updateUser)
}

function* fetchUserAll({ payload: data }) {
  try {
    yield put(userloadingtrue());
    const response = yield call(getAllUser(data));
    yield put(getUserData(response));
  } catch (error) {
    //yield put(getUserData(error));
  }
}

function* fetchDeleteUser({ payload:  { data, id }  }) {
  try {
    yield put(userloadingtrue());
    yield call(deleteuser(id));
    const response = yield call(getAllUser(data));
    yield put(getUserData(response));
  } catch (error) {
    yield put(getUserData(error));
  }
}

function* accountSaga() {
  yield takeEvery(GETUSER, fetchUserAll);
  yield takeEvery(DELETE_USER, fetchDeleteUser);

}




export default accountSaga
