import axios from "axios";

// Added by Parth Roka 05/12/2023 
const createMember = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.put(
      `${process.env.REACT_APP_API_URL}/admin/coupon/member/create`, dataBody,
      {
        headers: { Authorization: token },
      }
    );

    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Parth Roka 05/12/2023 
const memberListApi = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/member/list`, dataBody,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Parth Roka 
const CreateCouponNotifcationApi = async (dataBody) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/create`, dataBody,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

// Added by Parth Roka 
const CouponLogsNotifcationApi = async (dataBody) => {
  let token = localStorage.getItem("token");

  // url: `${process.env.REACT_APP_API_URL}/admin/coupon/logs` ,
  // `http://192.168.0.180:5000/admin/coupon/logs`

  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/logs/claim` , dataBody ,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

const CreditUsageAPI = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/logs/usage` , data ,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};




// Added by Moinuddin Mansuri 
const CouponCountAPI = async (jsonData) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/counts` , jsonData ,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};




// Added by Parth Roka 
const CouponListNotifcationApi = async (jsonData) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/coupon/list` , jsonData ,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};


// Added by Parth Roka 
const updatedAdminMaintance = async (id, data ) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/system/status/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }


  
};

// Added by Parth Roka 
const getMaintanceNotifcationApi = async () => {
  let token = localStorage.getItem("token");
  try {
    let config = {
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_API_URL}/admin/system/getsettings`,
      method: "GET",
    };
    let res = await axios(config);
    return { status: true, data: res.data, code: 200 };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

// Added by Parth Roka 
const getNotifcationApi = async () => {
  let token = localStorage.getItem("token");
  try {
    let config = {
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_API_URL}/admin-notification/get-all`,
      method: "GET",
    };
    let res = await axios(config);
    return { status: true, data: res.data, code: 200 };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

const getAdminTemplates = async () => {
  let token = localStorage.getItem("token");
  try {
    let config = {
      headers: {
        Authorization: token,
      },
      url: `${process.env.REACT_APP_API_URL}/admin-notification/gettamplate`,
      method: "GET",
    };
    let res = await axios(config);
    return { status: true, data: res.data, code: 200 };
  } catch (error) {
    return {
      status: false,
      data: error.response.data,
      code: error.response.status,
    };
  }
};

const updatedAdminTemplates = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin-notification/update/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (error) {
    return {
      data: "",
      error: error,
      status: 400,
    };
  }
};

export {createMember , memberListApi , CouponLogsNotifcationApi , CreditUsageAPI, CouponCountAPI, CreateCouponNotifcationApi ,getAdminTemplates, getNotifcationApi, updatedAdminTemplates , getMaintanceNotifcationApi , updatedAdminMaintance , CouponListNotifcationApi };
