const emailRegex = /^([a-zA-Z0-9\._]+)@([a-zA-Z0-9])+.([a-z])(.[a-z]+)?$/;
const phoneIn =/^((\+*)((0[ -]*)*|((91)*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}$/;
const upLowCaseReg=/^(?=.*[a-z])(?=.*[A-Z]).{2,}$/;
const numSpeCReg=/^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{2,}$/;

export {
    emailRegex,
    phoneIn,
    upLowCaseReg,
    numSpeCReg
}