import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MetaTags } from "react-meta-tags";
import AdminEmailNotification from "components/Notification/AdminEmailNotification";
import UserEmailNotification from "components/Notification/UserEmailNotification";
import {
  getAdminTemplates,
  getNotifcationApi,
  updatedAdminTemplates,
} from "service/notifactionService";
import UserSmsNotification from "components/Notification/UserSmsNotification";
import { showAlert } from "components/Common/AlertMessage";

const UiNotifications = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [adminEmail, setAdminEmail] = useState([]);
  const [adminSms, setAdminSms] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [userSms, setUserSms] = useState([]);
  const [template, setTemplate] = useState(null);
  const [templateEmail, setTemplateEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [load, setLoad] = useState(false)

  console.log("type", type)

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const getNotifcation = async () => {
    setLoading(true);
    const res = await getNotifcationApi();
    const optionGroup = [];
    const resps = await getAdminTemplates();
    if (resps.data.code == 200) {
      resps.data.data?.map((w) => {
        optionGroup.push({ label: w.name, value: w.id });
      });
    }
    if (res?.status) {
      const adminEmail = res.data.data.adminEmail.map((val) => {
        if (val.sgTemplateName !== null && val.sgTemplateId !== null) {
          val.value = { label: val.sgTemplateName, value: val.sgTemplateId };
        } else {
          val.value = null;
        }
        val.option = optionGroup;
        return val;
      });
      const adminSms = res.data.data.adminSms.map((val) => {
        return val;
      });
      const userEmail = res.data.data.userEmail.map((val) => {
        if (val.sgTemplateName !== null && val.sgTemplateId !== null) {
          val.value = { label: val.sgTemplateName, value: val.sgTemplateId };
        } else {
          val.value = null;
        }
        val.option = optionGroup;
        return val;
      });
      const userSms = res.data.data.userSms.map((val) => {
        return val;
      });

      setTemplateEmail(optionGroup);
      setAdminEmail(adminEmail);
      setAdminSms(adminSms);
      setUserEmail(userEmail);
      setUserSms(userSms);
      setLoading(false);
    } else {
      setLoading(false);

      toastr.error("something went wrong");
    }
  };

  useEffect(() => {
    getNotifcation();
    setLoad(true)
    setTimeout(() => {
      setLoad(false)
    }, 2000);
  }, []);

  const handleUpdatedSelect = async (value, data, checktype) => {
    setLoading(true);
    setType(checktype);
    let dataBody = {
      sgTemplateId: value.value,
      sgTemplateName: value.label,
    };
    let id = data._id;

    const res = await updatedAdminTemplates(id, dataBody);

    if (res.code == 200) {
      setLoading(false)
      showAlert("success", "Notification Updated Successful!");
      getNotifcation();
    } else {
      setLoading(false);
      setType("");
      showAlert("error", "There Is a Error Try Again");
    }
  };

  const handleUpdatedCheck = async (type, id, checktype) => {
    setLoading(true);
    setType(checktype);
    let dataBody = {
      status: type,
    };
    const res = await updatedAdminTemplates(id, dataBody);

    if (res.code == 200) {
      setLoading(false)
      showAlert("success", "Notification Updated Successful!");
      getNotifcation();
    } else {
      setLoading(false);
      setType("");
      showAlert("error", "There Is a Error Try Again");
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Notification | BrightSwipe</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Notification Settings" />
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Email Notifications</b>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>SMS Notifications</b>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="py-4 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">
                            ADMIN EMAIL NOTIFICATION
                          </h5>
                        </Col>
                        <Col sm="12" className="mb-4">
                          {loading && type == "adminEmail" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>
                          ) : (
                            <AdminEmailNotification
                              template={templateEmail}
                              notification={adminEmail}
                              handleUpdatedSelect={handleUpdatedSelect}
                              handleUpdatedCheck={handleUpdatedCheck}
                            />
                          )}
                        </Col>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">
                            USER EMAIL NOTIFICATION
                          </h5>
                        </Col>
                        {/* <Col sm="12">

                          {loading && type === "userEmail" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>
                          ) : (
                            <UserEmailNotification
                              template={templateEmail}
                              notification={userEmail}
                              handleUpdatedSelect={handleUpdatedSelect}
                              handleUpdatedCheck={handleUpdatedCheck}
                            />
                          )}
                        </Col> */}



                         <Col sm="12">
{
loading && load ? (  <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>) : 
                          loading && type === "userEmail" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>
                          ) : (
                            <UserEmailNotification
                              template={templateEmail}
                              notification={userEmail}
                              handleUpdatedSelect={handleUpdatedSelect}
                              handleUpdatedCheck={handleUpdatedCheck}
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">
                            USER SMS NOTIFICATION
                          </h5>
                        </Col>
                        <Col sm="12">

                          {
                            loading && load ? (  <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>) : 
                          loading && type === "userSms" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>
                          ) : (
                            <UserSmsNotification
                              template={template}
                              notification={userSms}
                              handleUpdatedCheck={handleUpdatedCheck}
                            />
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="theme-boxed bg-primary">
                <CardBody>
                  <CardTitle className="text-white">
                    Third-party integration
                  </CardTitle>
                  <Card>
                    <CardBody>
                      <ul className="p-0">
                        <li className="mb-3 list-style-none">
                          <h5 className="text-gray">Email Notifications</h5>
                          <p className="text-gray">
                            Managed via SendGrid API. To manage your email
                            templates and settings, visit sendgrid.com.
                          </p>
                        </li>
                        <li className="mb-3 list-style-none">
                          <h5 className="text-gray">SMS Notifications</h5>
                          <p className="text-gray">
                            Managed via Massage Bird API. To manage your SMS
                            settings, visit messagebird.com
                          </p>
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UiNotifications;
