import Swal from "sweetalert2";

export const showAlert = (icons, message) => {
  Swal.fire({
    toast: true,
    icon: icons,
    title: message,
    animation: false,
    position: "top-right",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};
