import UserTable from "components/Users/UserTable";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Users = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Users | BrightSwipe</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Users"
          /> */}
        </Container>
        <UserTable />
      </div>
    </React.Fragment>
  );
};

export default Users;
