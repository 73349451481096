import axios from "axios";

//pass new generated access token here
export const token =localStorage.getItem("token");

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

export const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] =token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);








