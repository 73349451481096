import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import toastr from "toastr";
import "react-datepicker/dist/react-datepicker.css";
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MetaTags } from "react-meta-tags";
import MaintenanceNotification from "components/Notification/MaintenanceNotification";
import { updatedAdminMaintance, getMaintanceNotifcationApi } from "service/notifactionService";
import { showAlert } from "components/Common/AlertMessage";
let startDate11 = "";
let endDate11 = "";

// Added by Parth Roka
const Maintenance = () => {
  const [togl, settogl] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [adminEmail, setAdminEmail] = useState([]);
  const [adminSms, setAdminSms] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [userSms, setUserSms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false)
  const [type, setType] = useState("");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  // for getsettings API call
  const getNotifcation = async () => {
    setLoading(true);
    const res = await getMaintanceNotifcationApi();
    if (res) {
      const adminEmail = res.data.data.map((val) => {
        return val;
      });
      setAdminEmail(adminEmail);
      setAdminSms(adminSms);
      setUserEmail(userEmail);
      setUserSms(userSms);
      setStartDate(startDate)
      setLoading(false);
    } else {
      setLoading(false);
      toastr.error("something went wrong");
    }
  };

  useEffect(() => {
    getNotifcation();
    setLoad(true)
    setTimeout(() => {
      setLoad(false)
    }, 2000);
  }, []);

  const [startDate, setStartDate] = useState(null);

  const handleDatePicker = async (value, data, checktype) => {
    setStartDate(value)
  }

  const handleStartDate = async (startdate) => {

    if (startdate) {
      startDate11 = startdate;
      setStartDate(startdate)
      settogl(!togl)
    }
  }

  const handleEndDate = async (enddate) => {
    if (enddate) {
      endDate11 = enddate;

    }
  }

  // for update maintenance status 
  const handleUpdatedCheck = async (type, id, start, end, checktype) => {

    setType(checktype);

    let simpleStartDate = new Date(startDate11 ? startDate11 : adminEmail ? adminEmail[0].start_time : "").toISOString();
    let simpleEndDate = new Date(endDate11 ? endDate11 : adminEmail ? adminEmail[0].end_time : "").toISOString();

    const formattedDate = new Date(simpleStartDate).toISOString("sv", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    const formattedDate22 = new Date(simpleEndDate).toISOString("sv", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    setLoading(true);
    let dataBody = {
      status: type,
      start_time: formattedDate.replace(" ", "T"),
      end_time: formattedDate22.replace(" ", "T"),
      is_scheduled: false,
    };
    // setLoading(false);

    const res = await updatedAdminMaintance(id, dataBody);

    if (res.code == 200) {
      setLoading(false)
      showAlert("success", "Maintenance Updated Successful!");
      getNotifcation();
    } else {
      setLoading(false);
      setType("");
      showAlert("error", "There Is a Error Try Again");
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Maintenance | BrightSwipe</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem="Notification Settings" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>App Maintenance</b>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>

                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="py-4 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">
                            App Maintenance
                          </h5>
                        </Col>
                        <Col sm="12" className="mb-4">

                          {
                            loading && load ? (  <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>) : 
                          loading && type == "Maintenance" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner
                                style={{ width: "2rem", height: "2rem" }}
                                children={false}
                              />
                            </div>
                          ) : (
                            <>
                              <MaintenanceNotification
                                notification={adminEmail}
                                handleDatePicker={handleDatePicker}
                                handleUpdatedCheck={handleUpdatedCheck}
                                startDate={handleStartDate}
                                endDate={handleEndDate}
                                ssdate={startDate11}
                              />
                            </>
                          )}
                        </Col>

                      </Row>
                    </TabPane>

                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Maintenance;
