import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { countryJson } from "./countrycodeList";
import { updateUser, getUserById } from "../../service/userService";
import { useParams } from "react-router-dom";
import UserRessetPasswordModal from "./resetPasswordModal";
import { showAlert } from "components/Common/AlertMessage";
import moment from "moment";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "components/Common/UserSuspendModal";
import { getUserAllData, deleteUser } from "../../service/userService";
import RemoveICon from "./../../assets/icons/remove-icon-white.svg";

const AddUsers = (props) => {
  const dispatch = useDispatch();
  const [selectedGroup, setselectedGroup] = useState({});
  const [countryCode, setCountryCode] = useState([]);
  const history = useHistory();
  const { id, row } = useParams();
  const [fieldData, setFieldData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [showText, setshowText] = useState("");
  const [fixStatus, setFixStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorShow, setErrorShow] = useState({
    code: false,
    noum: false,
    email: false,
    status: false,
    type: false,
    codet: "",
    noumt: "",
    emailt: "",
    statust: "",
    typet: "",
  });

  const styles = {
    color: "red",
    fontSize: "10px",
  };
  const optionGroup = [
    { label: "Active", value: "active" },
    { label: "Suspended", value: "suspended" },
    { label: "Archive", value: "archived" },
    { label: "Marked for deletion", value: "marked_for_deletion" },
  ];
  const { typeuser } = useSelector((state) => state.User);

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  useEffect(() => {
    let data = countryJson?.map((w) => {
      return {
        label: w.code,
        value: w.code,
      };
    });
    setCountryCode(data);
  }, []);

  const handleSubmit = async (e) => {
    let data = {
      email: fieldData.email,
      status: selectedGroup.status.value,
      customerType: selectedGroup.type.value,
    };

    if (
      fieldData.mobile_without_code !== null &&
      fieldData.mobile_without_code !== "" &&
      fieldData.mobile_without_code !== undefined
    ) {
      data.mobile_phone = `${"1"}${
        fieldData.mobile_without_code
      }`;
      data.mobile_without_code = fieldData.mobile_without_code;
      data.countryCode =  "1";
    }

    if (fieldData.mobile_without_code == "") {
      data.mobile_phone = null;
      data.mobile_without_code = null;
      data.countryCode = null;
    }
    if (fieldData.email == "") {
      data.email = null;
    }
    if (data.customerType == "admin") {
      data.role = "admin";
      delete data.customerType;
    } else {
      data.role = "user";
    }

    const resp = await updateUser(id, data);
    if (resp.code == 200) {
      showAlert("success", "User updated successfully");
      if (typeuser === "users") {
        history.push("/users");
      } else {
        history.push("/dashboard");
      }
    } else {
      showAlert(
        "error",
        resp.error.data.message || "there Is A error try again"
      );
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleApiCall = async () => {
    let resp = await getUserById(id);
    if (resp.code == 200) {
      setFieldData({
        ...fieldData,
        first_name: resp.data.users.first_name,
        last_name: resp.data.users.last_name,
        mobile_without_code: resp.data.users.mobile_without_code,
        email: resp.data.users.email,
        createdAt: resp.data.users.createdAt,
        updatedAt: resp.data.users.updatedAt,
      });
      setFixStatus(resp.data.users.status);
      let checkPre = {};
      if (resp.data.users.role == "admin") {
        checkPre.label = "Administrator";
        checkPre.value = resp.data.users.role;
      } else {
        checkPre.label =
          resp.data.users.customerType == "pre"
            ? "Pre-customer"
            : "Post-customer";
        checkPre.value = resp.data.users.customerType;
      }

      setselectedGroup({
        ...selectedGroup,
        countryCode: {
          label: capitalizeFirstLetter(resp?.data?.users?.countryCode || ""),
          value: resp.data.users.countryCode,
        },
        status: {
          label: capitalizeFirstLetter(
            replaceText(resp?.data?.users?.status) || ""
          ),
          value: resp.data.users.status,
        },
        type: checkPre,
        role: {
          label: capitalizeFirstLetter(resp.data.users.role || ""),
          value: resp.data.users.role,
        },
      });
    }
  };
  useEffect(() => {
    handleApiCall();
  }, [id]);

  const toggle = () => setIsOpen(!isOpen);

  const validateCheck = () => {
    let checkstatus = false;
    let datasend = errorShow;
    var regexp = /.+@.+\.[A-Za-z]+$/;
    // if (selectedGroup?.countryCode?.value == null) {
    //   checkstatus = true;
    //   datasend.code = true;
    //   datasend.codet = "Please Select Country Code";
    // }
    if (
      fieldData.mobile_without_code == "" ||
      fieldData.mobile_without_code == null
    ) {
      if (fieldData.email == "" || fieldData.email == null) {
        checkstatus = true;
        datasend.noum = true;
        datasend.noumt = "Please Enter Phone No. Or Email";
      }
    } else if (fieldData?.mobile_without_code?.length < 10) {
      checkstatus = true;
      datasend.noum = true;
      datasend.noumt = "Phone No. Must Be a 10 Digit No";
    }
    if (fieldData.email == "" || fieldData.email == null) {
      if (
        fieldData.mobile_without_code == "" ||
        fieldData.mobile_without_code == null
      ) {
        checkstatus = true;
        datasend.email = true;
        datasend.emailt = "Please Enter Email or Phone No";
      }
    } else if (!regexp.test(fieldData.email)) {
      checkstatus = true;
      datasend.email = true;
      datasend.emailt = "Please Enter Valid email";
    }
    if (selectedGroup?.status?.value == null) {
      checkstatus = true;
      datasend.status = true;
      datasend.statust = "Please Select Status";
    }
    if (selectedGroup?.type?.value == null) {
      checkstatus = true;
      datasend.type = true;
      datasend.typet = "Please Select Type";
    }
    setErrorShow(datasend);
    return checkstatus;
  };
  const [value, setValue] = useState();

  const handleDelete = async () => {
    const resp = await deleteUser(
      id,
      `${
        fixStatus == "active" || fixStatus == "suspended"
          ? "archived"
          : "marked_for_deletion"
      }`
    );
    // const resp = await deleteUser(id, "archived");
    if (resp.code == 200) {
      showAlert("success", "User Account is Archive");
      if (typeuser === "users") {
        history.push("/users");
      } else {
        history.push("/dashboard");
      }
    } else {
      showAlert(
        "error",
        resp.error.data.message || "there Is A error try again"
      );
    }
  };
  const handleDeletemodal = () => {
    setDelModal(true);
  };
  const onDelete = () => {
    setDelModal(false);
    handleDelete();
  };
  const onClose = () => {
    setDelModal(false);
  };

  const replaceText = (str) => {
    return str.replace(/_/g, " ");
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={delmodal}
        onDeleteClick={onDelete}
        onCloseClick={onClose}
        // status={"Archive"}
        status={
          fixStatus == "active" || fixStatus == "suspended"
            ? "archive"
            : "Marked for deletion"
        }
      />
      <UserRessetPasswordModal isOpen={isOpen} toggle={toggle} id={id} />
      <div className="page-content">
        <MetaTags>
          <title>
            {fieldData && fieldData ? "Edit User" : "User Details"} |
            BrightSwipe
          </title>
        </MetaTags>
        <Container fluid>
          <Row xs={"5"}>
            <Col sm="3" className="mb-3">
              <Label className="form-label">User Type</Label>
              <Select
                onBlur={() => {
                  if (selectedGroup?.type?.value == null) {
                    setErrorShow({
                      ...errorShow,
                      type: true,
                      typet: "Please Select Type",
                    });
                  } else {
                    setErrorShow({
                      ...errorShow,
                      type: false,
                      typet: "",
                    });
                  }
                }}
                value={selectedGroup.type || null}
                onChange={(e) => {
                  setselectedGroup({
                    ...selectedGroup,
                    type: e,
                  });
                  setErrorShow({
                    ...errorShow,
                    type: false,
                    typet: "",
                  });
                }}
                options={[
                  { label: "Pre-customer", value: "pre" },
                  { label: "Post-customer", value: "post" },
                  { label: "Administrator", value: "admin" },
                ]}
                classNamePrefix="select2-selection"
              />

              {errorShow.type && (
                <span type="invalid" style={styles}>
                  {errorShow.typet}
                </span>
              )}
            </Col>

            <Col sm="3" className="mb-3">
              <Label className="form-label">User Status</Label>
              <Select
                value={selectedGroup?.status || null}
                onBlur={() => {
                  if (selectedGroup?.status?.value == null) {
                    setErrorShow({
                      ...errorShow,
                      status: true,
                      statust: "Please Select Status",
                    });
                  } else {
                    setErrorShow({
                      ...errorShow,
                      status: false,
                      statust: "",
                    });
                  }
                }}
                onChange={(e) => {
                  setselectedGroup({
                    ...selectedGroup,
                    status: e,
                  });
                  setErrorShow({
                    ...errorShow,
                    status: false,
                    statust: "",
                  });
                }}
                options={optionGroup}
                classNamePrefix="select2-selection"
              />

              {errorShow.status && (
                <span type="invalid" style={styles}>
                  {errorShow.statust}
                </span>
              )}
            </Col>
            <Col style={{ marginTop: 29 }}>
              {/* {fixStatus?<Button
            style={{padding:12}}
              className={"font-size-12 badge-soft-" + fixStatus}
              disabled
              >
            {capitalizeFirstLetter(fixStatus || "")}
              </Button>:""} */}
              {fixStatus && (
                <Button style={{ padding: 12 }} onClick={handleDeletemodal}>
                  <img src={RemoveICon} alt="remove icon" className="me-1" />{" "}
                  {fixStatus == "active" || fixStatus == "suspended"
                    ? "Archive"
                    : "Marked for deletion"}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Card>
            <CardBody>
              <div className="mb-4 h4 text-gray card-title">
                Basic Information
              </div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();

                  if (!validateCheck()) {
                    handleSubmit(e);
                  }
                }}
              >
                <Row>
                  <Row xs={"4"} style={{ marginBottom: 95 }}>
                    {/* <Col sm="4" className="mb-3">
                    <Label className="form-label">Country Code</Label>
                    <Select
                      value={selectedGroup.countryCode || null}
                      onBlur={() => {
                        if (selectedGroup?.countryCode?.value == null) {
                          setErrorShow({
                            ...errorShow,
                            code: true,
                            codet: "Please Select Country Code",
                          });
                        } else {
                          setErrorShow({
                            ...errorShow,
                            code: false,
                            codet: "",
                          });
                        }
                      }}
                      onChange={(e) => {
                        setselectedGroup({
                          ...selectedGroup,
                          countryCode: e,
                        });
                        setErrorShow({
                          ...errorShow,
                          code: false,
                          codet: "",
                        });
                      }}
                      options={countryCode}
                      classNamePrefix="select2-selection"
                    />

                    {errorShow.code && (
                      <span type="invalid" style={styles}>
                        {errorShow.codet}
                      </span>
                    )}
                  </Col> */}
                    <Col sm="3" className="mb-3">
                      <Label className="form-label">Phone Number</Label>
                      <Input
                        value={fieldData.mobile_without_code || ""}
                        name="mobile_without_code"
                        className="form-control"
                        placeholder="Enter phone number"
                        type="text"
                        onBlur={() => {
                          if (
                            fieldData.mobile_without_code == "" ||
                            fieldData.mobile_without_code == null
                          ) {
                            if (
                              fieldData.email == "" ||
                              fieldData.email == null
                            ) {
                              setErrorShow({
                                ...errorShow,
                                noum: true,
                                noumt: "Please Enter Email or Phone No",
                              });
                            }
                          } else if (
                            fieldData?.mobile_without_code?.length < 9
                          ) {
                            setErrorShow({
                              ...errorShow,
                              noum: true,
                              noumt: "Phone No. Must Be a 10 Digit No",
                            });
                          } else {
                            setErrorShow({
                              ...errorShow,
                              noum: false,
                              noumt: "",
                            });
                          }
                        }}
                        onChange={(e) => {
                          var numbers = /^\d*\d*$/;
                          if (
                            e.target.value.match(numbers) &&
                            e.target.value.length <= 10
                          ) {
                            setFieldData({
                              ...fieldData,
                              mobile_without_code: e.target.value,
                            });
                          }
                          if (
                            fieldData.mobile_without_code == "" ||
                            fieldData.mobile_without_code == null
                          ) {
                            if (
                              fieldData.email == "" ||
                              fieldData.email == null
                            ) {
                              setErrorShow({
                                ...errorShow,
                                noum: true,
                                noumt: "Please Enter Email or Phone No",
                              });
                            }
                          } else if (
                            fieldData?.mobile_without_code?.length < 9
                          ) {
                            setErrorShow({
                              ...errorShow,
                              noum: true,
                              noumt: "Phone No. Must Be a 10 Digit No",
                            });
                          } else {
                            setErrorShow({
                              ...errorShow,
                              noum: false,
                              noumt: "",
                            });
                          }
                        }}
                      />

                      {errorShow.noum && (
                        <span type="invalid" style={styles}>
                          {errorShow.noumt}
                        </span>
                      )}
                    </Col>
                    <Col sm="3" className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        value={fieldData.email || ""}
                        name="email"
                        className="form-control"
                        placeholder="Enter email address"
                        type="text"
                        onBlur={() => {
                          var regexp = /.+@.+\.[A-Za-z]+$/;
                          if (
                            fieldData.email == "" ||
                            fieldData.email == null
                          ) {
                            if (
                              fieldData.mobile_without_code == "" ||
                              fieldData.mobile_without_code == null
                            ) {
                              setErrorShow({
                                ...errorShow,
                                email: true,
                                emailt: "Please Enter Email or Phone No",
                              });
                            }
                          } else if (!regexp.test(fieldData.email)) {
                            setErrorShow({
                              ...errorShow,
                              email: true,
                              emailt: "Please Enter Valid email",
                            });
                          } else {
                            setErrorShow({
                              ...errorShow,
                              email: false,
                              emailt: " ",
                            });
                          }
                        }}
                        onChange={(e) => {
                          setFieldData({
                            ...fieldData,
                            email: e.target.value,
                          });
                          setErrorShow({
                            ...errorShow,
                            email: false,
                            emailt: " ",
                          });
                        }}
                      />

                      {errorShow.email && (
                        <span type="invalid" style={styles}>
                          {errorShow.emailt}
                        </span>
                      )}
                    </Col>

                    <Col sm="3" className="mb-3">
                      <Label className="form-label">Creation date</Label>
                      <p className="field-text1">
                        {moment(new Date(fieldData?.createdAt)).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </Col>
                    <Col sm="3" className="mb-3">
                      <Label className="form-label">User Id</Label>
                      <p className="field-text1">{id}</p>
                    </Col>
                  </Row>

                  <Col sm="16" className="my-3">
                    <div className="h2 border-bottom-gray pb-3 text-gray card-title">
                      SECURITY SETTINGS
                    </div>
                  </Col>
                  <Col sm="16" className="border-bottom-gray2 pb-3 mb-3">
                    <button
                      type="button"
                      className="btn btn-secondary "
                      onClick={() => {
                        toggle();
                      }}
                    >
                      Reset Password
                    </button>
                  </Col>
                  <Col sm="16" className="mb-3">
                    <button
                      type="button"
                      className="btn btn-success me-3"
                      onClick={() => {
                        if (!validateCheck()) {
                          handleSubmit();
                        }
                      }}
                    >
                      Save Changes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        if (typeuser === "users") {
                          history.push("/users");
                        } else {
                          history.push("/dashboard");
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddUsers;
