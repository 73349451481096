import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import Swal from "sweetalert2";
import { getUserProfile } from "../../service/userService";

const WelcomeComp = () => {
  const [userData, setUsetData] = useState({});
  const handleApidata = async () => {
    let resp = await getUserProfile();
    if (resp.code == 200) {
      setUsetData(resp.data);
    }
  };
  useEffect(() => {
    handleApidata();
  }, []);
  return (
    <React.Fragment>
      <Card className="mini-stats-wid home-card23 overflow-hidden">
        <Row>
          <Col xs="12">
            <div className="text-primary p-3">
              <h3 className="text-primary fw-bold">
                Welcome Back ! <br />
                Admin
              </h3>
              <span className="d-flex">
                <Link
                  to="/profile"
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                >
                  Manage your profile <i className="bx bx-cog font-md ms-2"></i>
                </Link>
              </span>
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
