import React, { useRef, useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Card,CardBody, Col,Container, Form,Input, Label, Row, Spinner } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  uplodeImage,
  getSinglePageData,
  updatePage,
} from "../../service/pageService";
import { useHistory, useParams } from "react-router-dom";
import { showAlert } from "components/Common/AlertMessage";
import { CKEditor } from 'ckeditor4-react';

const EditContentManagement = () => {
  const { id } = useParams();
  const [data, setData] = useState({
    title: "",
    slug: "",
    image: "",
    type: "",
  });
  const [contentValue, setChangeContent] = useState("");
  const [rufImge, setRufImage] = useState("");
  const [MyTitle, setTitile] = useState("");
  const history = useHistory();
  const pic =
    "https://media.istockphoto.com/vectors/no-photo-available-vector-icon-default-image-symbol-picture-coming-vector-id1354776450?s=612x612";
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [imageObject, setImageObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [picLoading, setPicLoading] = useState(false);
  const [checkImg, setCheckImg] = useState(true);

  useEffect(() => {
    handleApiCall();
  }, [id]);

  const handleApiCall = async () => {
    setLoading(true);
    let resp = await getSinglePageData(id);
    if (resp.code == 200) {
      setLoading(false);
      setPicLoading(true);
      setImage(`${process.env.REACT_APP_API_URL}/${resp.data.image}`);
      setTitile(resp.data.title);
      setData(resp.data);
      setChangeContent(resp.data.content);
      setRufImage(resp.data.image);
      setPicLoading(false);
    }
  };
  const { title, slug, content, isImage } = data;

  const HandleInputField = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    if (!event.target.files[0].type.includes("image")) {
      return showAlert("error", "Please select only images.");
    }

    if (event.target.files[0].size > 2 * 1024 * 1024) {
      return showAlert("error", "Image size must be less than 2 mb.");
    }

    const fileObj = event.target.files && event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = function () {
      setImage(reader.result);
    };
    reader.readAsDataURL(fileObj);
    if (!fileObj) {
      return;
    }
    setImageObject(fileObj);
  };

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  const handleSubmitChange = async () => {
    let value = {
      title: data.title,
      slug: data.slug,
      image: data.image,
      content: contentValue,
      type: data.type,
    };
    setLoading(true);
    let img = null;
    if (imageObject) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", imageObject);
      let imageurl = await uplodeImage(bodyFormData);
      img = imageurl.data.path;
    }
    if (img) {
      value.image = img;
    } else {
      value.image = rufImge;
    }
    const resp = await updatePage(id, value);
    if (resp.code == 200) {
      setLoading(false);
      showAlert("success", "Content page updated successfully");
      history.push("/content-manager");
    } else {
      setLoading(false);
      showAlert(
        "error",
        resp.error.response.data.message || "There Is a error try again"
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Add Content Management | BrightSwipe </title>
        </MetaTags>
        <Container fluid className="page-head34">
          {/* Render Breadcrumb */}
          {MyTitle ? (
            <Row xs={"2"}>
              <Breadcrumbs
                title="Dashboards"
                breadcrumbItem={`Content Editor For ${MyTitle} `}
              />
            </Row>
          ) : (
            ""
          )}
        </Container>
        <Container fluid>
          <Card>
            <CardBody>
              {!loading ? (
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();

                    return false;
                  }}
                >
                  <Row>
                    <Col sm="4" className="mb-3">
                      <Label className="form-label">Headline</Label>
                      <Input
                        value={title}
                        name="title"
                        className="form-control"
                        placeholder="Enter title"
                        type="text"
                        onChange={HandleInputField}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8" className="mb-3">
                      <div className="textarea-content border-bottom-gray2 pb-4">
                        <Label className="form-label">Content</Label>
                        {contentValue &&
                              <CKEditor initData={contentValue} onChange={(event) => setChangeContent(event.editor.getData())} />}
                      </div>
                      <div className="form-action pt-3">
                        <button
                          type="button"
                          className="btn btn-primary me-3"
                          onClick={handleSubmitChange}
                        >
                          Save Changes
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            history.push("/content-manager");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </Col>
                    <Col sm="4" className="mb-3">
                      {isImage && isImage && (
                        <>
                          <>
                            {picLoading ? (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <Spinner
                                  style={{ width: "2rem", height: "2rem" }}
                                  children={false}
                                />
                              </div>
                            ) : (
                              <div className="profile-picture24 ">
                                <img
                                  src={image == "" ? pic : image}
                                  alt="picture new"
                                  crossorigin="anonymous"
                                  className="img-fluid w-100"
                                />
                              </div>
                            )}
                          </>
                          <div className="field-group mt-4">
                            <Label className="form-label">About us image</Label>
                            <Input
                              value={slug}
                              name="slug"
                              className="form-control"
                              placeholder="uploaded image name"
                              type="text"
                              onChange={HandleInputField}
                            />
                          </div>

                          <div className="upload-profile d-flex align-items-start mt-3">
                            <input
                              style={{ display: "none" }}
                              ref={inputRef}
                              max-size="1048576"
                              type="file"
                              accept="image/png, image/jpeg"
                              onChange={handleFileChange}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary me-3"
                              onClick={handleClick}
                            >
                              Upload
                            </button>
                            <p className="text-gray">
                              Accepted formats: JPG, PNG Your image must be up
                              to 512x512px and cannot be over 2MB.{" "}
                            </p>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </Form>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditContentManagement;
