import React from "react";

export const theme = {
  user: {
    name: "rohit",
    lastname: "bansal",
  },
};

const UserContxtCheck = React.createContext("theme.user");

export default UserContxtCheck;
