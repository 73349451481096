import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CHARTS_DATA } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import { getAllUsers } from "helpers/apis";

function* getChartsData({ payload: filter }) {
  try {
    if (filter == "users") {
      var responce = yield call(getAllUsers);
      if (responce) {
        yield put(apiSuccess(GET_CHARTS_DATA, responce));
      }
    } else if (filter == "orders") {
    }
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error));
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;
