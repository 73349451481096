import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import { getOrderSingleData } from "service/orderService";
import moment from "moment";

const OrdersDetails = () => {
  const { id } = useParams();
  const [orderDetail, setOrderdetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    orderDetails();
  }, [id]);

  const orderDetails = async () => {
    setLoading(true);
    const resp = await getOrderSingleData(id);
    if (resp.code == 200) {
      setLoading(false);
      setOrderdetail(resp.data[0]);
    } else {
      setLoading(false);
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Order Details | BrightSwipe</title>
        </MetaTags>
        <Container fluid>
        </Container>
        <Container fluid>
          <Card className="card-light-gray">
            <CardBody>
              {!loading ? (
                <>
                  {" "}
                  <Row className="border-bottom-gray pb-3 mb-3">
                    <Col sm={12} className="mx-2">
                      <h5 className="mb-0 text-gray font-weight-700 font-size-20 d-flex align-items-end">
                        Order ID: {orderDetail._id || ""}{" "}
                        <small className="px-3 mb-0 font-size-11px">
                          Transaction ID : {orderDetail.transaction_id || ""}
                        </small>{" "}
                        <h6 className="mb-0 text-gray">
                          <Row className="align-items-end">
                            <Col
                              sm={12}
                              className="white-break d-flex flex-wrap font-size-16 fw-bold"
                            >
                              SERVICE: &nbsp;{" "}
                              <div className="space-noWrap">
                                {orderDetail?.order?.map((w, i) => {
                                  let checklength = orderDetail?.order?.length;
                                  return (
                                    <span className="text-uppercase">
                                      {w?.service_name || ""}
                                      {i >= 0 && i + 1 < checklength && " - "}
                                    </span>
                                  );
                                })}
                              </div>
                            </Col>
                          </Row>{" "}
                        </h6>
                      </h5>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col sm={4}>
                      <h6 className="text-gray">
                        <b>Customer</b>
                      </h6>
                      <p className="text-gray mb-0">
                        ID {orderDetail.user_id || ""}
                      </p>
                      <small className="text-gray">
                        {orderDetail.user_details?.customer_email || orderDetail.user_details?.customer_phone || "User Deleted"  }
                      </small>
                    </Col>
                    <Col sm={4}>
                      <h6 className="text-gray">
                        <b>Submitted</b>
                      </h6>
                      <p className="text-gray mb-0">
                        {moment(orderDetail.createdAt || "").format(
                          "DD / MM / YYYY"
                        )}
                      </p>
                      <p className="text-gray mb-0">
                        {moment(orderDetail.createdAt || "").format(
                          "hh:mm:ss a"
                        )}
                      </p>
                    </Col>
                    <Col sm={4}>
                      <h6 className="text-gray">
                        <b>Status</b>
                      </h6>
                      <p className="text-gray mb-0">
                        <Badge
                          className={
                            "font-size-12 badge-soft-" +
                            orderDetail.payment_status
                          }
                          color={orderDetail.badgeClass}
                          pill
                        >
                          {capitalizeFirstLetter(
                            orderDetail?.payment_status == "success"
                              ? "Complete"
                              : orderDetail.payment_status || ""
                          )}
                        </Badge>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col
                      sm={12}
                      className="p-3 mx-2 background-bg-gray d-flex align-items-center text-gray"
                    >
                      <h4 className="mb-0 fw-bold">Financials</h4>
                    </Col>
                  </Row>
                  <div className="financial-table">
                    <Row>
                      <Col sm={4}>
                        <div className="td-lead p-2">
                          <h5 className="text-gray">
                            <b>SERVICE PRICING</b>
                          </h5>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="td-lead p-2">
                          <h5 className="text-gray">
                            <b>PAYMENT PROCESSOR</b>
                          </h5>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="td-lead p-2">
                          <h5 className="text-gray">
                            <b>PAYMENT PROCESSOR FEE</b>
                          </h5>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <div className="td-lead p-2">
                          {orderDetail.order?.map((data) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h5 className="text-gray">
                                  {" "}
                                  <b>{data.service_name} </b>
                                </h5>
                                <h5 className="text-gray">
                                  {" "}
                                  <b>$ {data.service_price}</b>
                                </h5>
                              </div>
                            );
                          })}

                          {orderDetail.order?.length == 3 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                            </div>
                          )}
                          {orderDetail.order?.length == 3 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <h5 className="text-gray">
                                {" "}
                                <b>Full Check</b>
                              </h5>
                              <h5 className="text-gray">
                                {" "}
                                <b>$ {orderDetail.total}</b>
                              </h5>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="td-lead p-2">
                          <h5 className="text-gray">
                            <b>{orderDetail.processer}</b>
                          </h5>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="td-lead p-2">
                          <h5 className="text-gray">
                            <b>$ {orderDetail?.platfrom_fee || 0}</b>
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col sm={12}>
                      <div className="td-lead p-3 text-end">
                        <h4 className="text-gray">
                          <b>TOTAL : ${orderDetail.total || ""}</b>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {loading ? <Spinner /> : ""}
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrdersDetails;
