import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Col, Row } from "reactstrap";
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
var sdate = ''
var edate = ''

// Added by Parth Roka
const MaintenanceNotification = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [notiData, setAdminNoti] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [sday, setSday] = useState();
  const [eday, setEday] = useState();
  const dateFormat = 'MM-DD-YYYY HH:mm';

  const handleStartDate = (e) => {
    let value = e.target.value;
    if (value) {
      if (edate) {
        if (new Date(edate) >= new Date(value)) {
          const formattedDate = moment(value, 'YYYY-MM-DDTHH:mm').format(dateFormat);
          setSday(formattedDate)
          setStartDate(value)
          sdate = value
          props.handleDatePicker(value, "startdate");
          EcommerceOrderColumns();
          props.startDate(e.target.value)
        } else {

          setSday("")
          alert("Start date should not greter then End date")
          sdate = "";
          edate = "";
          setStartDate("")
          let dateEle1 = document.getElementById("startdate");
          let dateEle2 = document.getElementById("enddate");

          dateEle1.setAttribute('data-date', "MM-DD-YYYY HH:mm");
          dateEle2.setAttribute('data-date', "MM-DD-YYYY HH:mm");

          props.handleDatePicker("", "startdate");
          props.startDate("")
          props.handleDatePicker("", "enddate");
          props.endDate("");
          props.startDate("");
          document.getElementById("enddate").value = "";
          document.getElementById("startdate").value = "";
        }
      } else {
        const formattedDate = moment(value, 'YYYY-MM-DDTHH:mm').format(dateFormat);
        setSday(formattedDate)
        setStartDate(value)
        sdate = value
        props.handleDatePicker(value, "startdate");
        EcommerceOrderColumns();
        props.startDate(e.target.value)
      }

    } else {
      sdate = "";
      setStartDate("")
      props.handleDatePicker("", "startdate");
      props.startDate("");
    }
  };

  const handleEndDate = (e) => {
    let value = e.target.value
    if (value) {
      if (new Date(sdate) <= new Date(value)) {
        const formattedDate = moment(value, 'YYYY-MM-DDTHH:mm').format(dateFormat);
        setEday(formattedDate)
        edate = value
        props.handleDatePicker(value, "enddate");
        setEndDate(value);
        props.endDate(value)
      } else {
        edate = "";
        sdate = "";
        setEday("")

        alert("End date should not less then Start date");
        let dateEle1 = document.getElementById("startdate");
        let dateEle2 = document.getElementById("enddate");

        dateEle1.setAttribute('data-date', "MM-DD-YYYY HH:mm");
        dateEle2.setAttribute('data-date', "MM-DD-YYYY HH:mm");
        props.handleDatePicker("", "startdate");
        props.startDate("")
        props.handleDatePicker("", "enddate");
        props.endDate("");
        document.getElementById("enddate").value = "";
        document.getElementById("startdate").value = "";
      }

    } else {
      edate = "";
      setEday("")
      setEndDate("")
      props.handleDatePicker("", "enddate");
      props.endDate("");
    }
  };

  useEffect(() => {
    setAdminNoti(props?.notification);
  }, [props]);

  // for get status data from API's
  useEffect(() => {
    if (props.notification) {

      if (props.notification.length > 0) {
        let formattedStartDatee = props.notification[0]["start_time"].toLocaleString();
        let formattedEndDatee = props.notification[0]["end_time"].toLocaleString();

        sdate = moment(formattedStartDatee).format('YYYY-MM-DDTHH:mm')
        edate = moment(formattedEndDatee).format('YYYY-MM-DDTHH:mm')

        const dateFormat = 'MM-DD-YYYY HH:mm';
        setSday(moment(formattedStartDatee).format(dateFormat))
        setEday(moment(formattedEndDatee).format(dateFormat))
      }
    }
  }, [props.notification])

  useEffect(() => {
    if (sday) {
      var dateEle1 = document.getElementById("startdate")
      if (dateEle1) {
        dateEle1.setAttribute('data-date', sday ? sday : 'MM-DD-YYYY HH:mm');

      }
    }
  }, [sday])

  useEffect(() => {
    if (eday) {
      var dateEle = document.getElementById("enddate")
      if (dateEle) {
        dateEle.setAttribute('data-date', eday ? eday : 'MM-DD-YYYY HH:mm');

      }
    }
  }, [eday])

  const selectRow = {
    mode: "none",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: notiData?.length, // replace later with size(orders),
    custom: true,
  };
  const toggleViewModal = () => setModal1(!modal1);

  let today = new Date().toISOString().slice(0, 16);
  var selectedDate = "0000-00-00T0:00:00.000Z";
  var selecteEndDate = "0000-00-00T0:00:00.000Z";

  if (notiData.length > 0) {
    selectedDate = notiData[0]["start_time"].toLocaleString();
    selecteEndDate = notiData[0]["end_time"].toLocaleString();
  }

  let formattedDate = new Date(selectedDate).toLocaleString("sv", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  let formattedEndDate = new Date(selecteEndDate).toLocaleString("sv", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  const EcommerceOrderColumns = (toggleModal, endd) => [
    {
      dataField: "text",
      text: "MAINTENANCE",
      formatter: (cellContent, row) => <>Status</>,
    },
    {
      dataField: "text2",
      text: "APP MAINTENANCE START DATE",
      formatter: (cellContent, row, rowIndex) => (
        <>

          <input
            className="date-div"
            type="datetime-local"
            id="startdate"
            value={startDate}
            defaultValue={row.status === false ? "" : formattedDate ? formattedDate.replace(" ", "T") : ""}
            min={today}
            max={endDate}
            onChange={(e) => {
              (handleStartDate(e))
            }}
            disabled={row.status ? true : false}
            data-date="MM-DD-YYYY HH:mm"
            data-date-format="MM-DD-YYYYTHH:mm"

          />
        </>
      ),
    },

    {
      dataField: "text3",
      text: "APP MAINTENANCE COMPLETION  DATE",
      formatter: (cellContent, row, rowIndex) => (
        <>
          <input
            className="date-div"
            type="datetime-local"
            id="enddate"
            // hidden={endDate ?  false : true}
            value={endDate}
            defaultValue={row.status === false ? "" : formattedEndDate ? formattedEndDate.replace(" ", "T") : ""}
            min={startDate ? startDate : today}
            onChange={(e) => handleEndDate(e)}
            disabled={row.status ? true : false}
            data-date="MM-DD-YYYY HH:mm"
            data-date-format="MM-DD-YYYYTHH:mm"
          />
        </>
      ),
    },

    {
      dataField: "status",
      isDummyField: true,
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div className="form-check d-flex justify-content-end form-switch form-switch-lg mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizel"
            checked={row.status ? true : false}
            onChange={(e) => {
              if (sdate && edate) {
                props.handleUpdatedCheck(e.target.checked, row._id, startDate, endDate, "Maintenance");
              }
              else {
                alert("Please select start date and end date ")
              }
            }}
          />
          <label
            className={
              row.status ? "form-check-label checked" : "form-check-label"
            }
            htmlFor="customSwitchsizel"
          >
            {row.status == true ? "ON" : "OFF"}
          </label>
        </div>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "_Id",
    },
  ];

  return (
    <React.Fragment>


      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={EcommerceOrderColumns(toggle, endDate)}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            data={notiData || []}
            columns={EcommerceOrderColumns(toggle, endDate)}
            bootstrap4
            search
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive maintenance-div-new">
                      <BootstrapTable
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={selectRow}
                        classes={
                          "table align-middle table-nowrap tableNotificationNew table-no-check table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="pagination pagination-rounded justify-content-center">
                </div>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  );
};

MaintenanceNotification.propTypes = {
  notifaction: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(MaintenanceNotification);





