import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { showAlert } from "components/Common/AlertMessage";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,

  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {

      showAlert("error", "Session timeout. Please login again");
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );

     
        
      } else if (!isAuthProtected && localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
