import axios from "axios";

export const uplodeImage = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/page/uplode-multer`,
      data,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return resp;
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const addPage = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/page`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const getAllPageData = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/page/get-all`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const getSinglePageData = async (id) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/page/${id}`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const updatePage = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/page/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const getAllService = async () => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/service`, {
      headers: { Authorization: token },
    });
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const getSingleServiceData = async (id) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/service/${id}`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};

export const updateService = async (id, data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.put(
      `${process.env.REACT_APP_API_URL}/service/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};
