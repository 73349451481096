import { act } from "react-dom/test-utils"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS, OTP_LOGIN, OTP_SUCCESS, OTP_ERROR,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  loginUserData: "",
  loading2: false
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        loading2: true,
        error: ""
      }
      break
    case OTP_LOGIN:
      state = {
        ...state,
        loading: true,
        loading2:true,
        error: ""
      }
      break
    case OTP_SUCCESS:
      state = {
        ...state,
        loading: false,
        loading2: false,
        // error: "",
        loginUserData: action.payload
      }
      break
      case OTP_ERROR:
      state = {
        ...state,
        loading: false,
        loading2: false,
        error: action.payload,
        // loginUserData: action.payload
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        loginUserData: action.payload
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
