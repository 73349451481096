import ContentTable from "components/ContentManagement/contentTable";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ContentManager = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Content Manager | BrightSwipe </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbItem="Content Management" />
        </Container>
        <ContentTable />
      </div>
    </React.Fragment>
  );
};

export default ContentManager;
