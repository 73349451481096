import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, OTP_LOGIN, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess ,logoutUserSuccess, otpSuccess, otpErr } from "./actions";

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import { postLogin  , postOtp} from "helpers/apis";
import { userForgetPasswordSuccess, userForgetPasswordError } from "../forgetpwd/actions";

// const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (user.match == "loginOtp") {
      const res = yield call(postLogin,user);
        if (res) {
          const message = {
            user: user,
            data: res.data,
            message: "An otp has been sent to your registered email",
          };
          localStorage.setItem("verfyEmial", JSON.stringify(message));
          // yield put(userForgetPasswordSuccess(message));
          yield put(otpSuccess(message));
          history.push("/verify-email");
        }
      // localStorage.setItem("authUser", JSON.stringify(res.data));
      // localStorage.setItem( "token" ,res.accessToken);
      // yield put(loginSuccess(res.data));
    }
  } catch (error) {
    yield put(apiError(error.message));
    // yield put(userForgetPasswordError(error));
    yield put(otpErr(error.message));
  }
}

function* OtpUserLogin({ payload: { user, history } }) {
  try {
      const res = yield call(postOtp,user);
      if(res){
      localStorage.setItem("authUser", JSON.stringify(res.data));
      localStorage.setItem( "token" ,res.accessToken);
      yield put(otpSuccess(res.data));
      history.push("/dashboard");
    }
  } catch (error) {
    // yield put(apiError(error.message));
    // yield put(userForgetPasswordError(error.message));
    yield put(otpErr(error.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
     history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(OTP_LOGIN, OtpUserLogin);
}

export default authSaga;
