import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//user
import User from "./users/reducer";

// orders
import Order from "./orders/reducer";

const rootReducer = combineReducers({
  // public
  Order,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  Dashboard,
  User,
});

export default rootReducer;
