import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ShowService = (props) => {
  const { isOpen, toggle, showData } = props;
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => toggle(false)}
    >
      <div className="modal-content">
        <ModalHeader toggle={() => toggle(false)}>{showData.name}</ModalHeader>
        <ModalBody>
          <div>
            <p>
              <b>Price :-</b> {showData.price}
            </p>
            <p>
              <b>Description :-</b> {showData.description}
            </p>
            <p>
              <b>Benefit :- </b>
              {renderHTML(showData.benefit)}
            </p>
          </div>
          <div className="text-center serviceShowMedia">
            <img
              src={`${process.env.REACT_APP_API_URL}/${showData.image}`}
              crossorigin="anonymous"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={() => toggle(false)}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default ShowService;
