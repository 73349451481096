import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { countryJson } from "./countrycodeList";
import { retry } from "redux-saga/effects";
import { addUser } from "../../service/userService";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  roleGroup,
  statusGroup,
  typeGroup,
} from "components/Common/DropdownGroup";
import { showAlert } from "components/Common/AlertMessage";
import PhoneInput from "react-phone-number-input";
const AddUsers = (props) => {
  const [selectedGroup, setselectedGroup] = useState({});
  const [countryCode, setCountryCode] = useState([]);
  const [passwordEnter, setPasswordEnter] = useState("");
  const history = useHistory();
  const [fieldData, setFieldData] = useState({
    email: "",
    mobile_without_code: "",
  });
  const [errorShow, setErrorShow] = useState({
    noum: false,
    email: false,
    noumt: "",
    emailt: "",
  });
  const styles = {
    color: "red",
    fontSize: "10px",
  };


  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid red !important",
    }),
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      mobile_without_code: "",

      status: "",
      customerType: "",
      password: "",
    },

    validationSchema: Yup.object({
      // mobile_without_code: Yup.string()
      //   .required("Please Enter  Phone Number")
      //   .min(9, "Phone no Must be more than 8 digits"),
      // email: Yup.string()
      //   .required("Please Enter  Email Address")
      //   .email("Enter Valid Email"),
      password: Yup.string()
        .required("Please Enter  Password")
        .min(6, "Password Should be more than 5 digits"),
      status: Yup.string().required("Please Select  Status"),
      customerType: Yup.string().required("Please Select  Type "),
    }),
    onSubmit: (values) => {
      values.mobile_without_code = String(values.mobile_without_code);
      handleSubmit(values);
    },
  });

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  useEffect(() => {
    let data = countryJson?.map((w) => {
      return {
        label: w.code,
        value: w.code,
      };
    });
    setCountryCode(data);
  }, []);

  const handleCehckTwoError = () => {
    let checkstatus = false;
    let datasend = errorShow;
    var regexp = /.+@.+\.[A-Za-z]+$/;

    if (
      fieldData.mobile_without_code == "" ||
      fieldData.mobile_without_code == null
    ) {
      if (fieldData.email == "" || fieldData.email == null) {
        checkstatus = true;
        datasend.noum = true;
        datasend.noumt = "Please Enter Phone Number";
      }
    } else if (fieldData?.mobile_without_code?.length < 10) {
      checkstatus = true;
      datasend.noum = true;
      datasend.noumt = "Phone No. Must Be a 10 Digit No";
    }
    if (fieldData.email == "" || fieldData.email == null) {
      if (
        fieldData.mobile_without_code == "" ||
        fieldData.mobile_without_code == null
      ) {
        checkstatus = true;
        datasend.email = true;
        datasend.emailt = "Please Enter Email";
      }
    } else if (!regexp.test(fieldData.email)) {
      checkstatus = true;
      datasend.email = true;
      datasend.emailt = "Please Enter Valid Email";
    }
    setErrorShow(datasend);

    return checkstatus;
  };

  const handleSubmit = async (value) => {
    let countryCode = "1";

    if (value.customerType == "admin") {
      value.role = "admin";
      delete value.customerType;
    } else {
      delete value.role;
    }

    if (
      fieldData.email !== "" &&
      fieldData.email !== null &&
      fieldData.email !== undefined
    ) {
      value.email = fieldData.email;
    }

    if (
      fieldData.mobile_without_code !== "" &&
      fieldData.mobile_without_code !== null &&
      fieldData.mobile_without_code !== undefined
    ) {
      value.countryCode = countryCode;
      value.mobile_phone = `${countryCode}${fieldData.mobile_without_code}`;
      value.mobile_without_code = fieldData.mobile_without_code;
    }

    if (fieldData.email == "") {
      value.email = null;
    }
    if (fieldData.mobile_without_code == "") {
      value.countryCode = null;
      value.mobile_phone = null;
      value.mobile_without_code = null;
    }

    if (!handleCehckTwoError()) {
      const resp = await addUser(value);
      if (resp.code == 200) {
        history.push("/users");
        showAlert("success", "User created successfully");
      } else {
        showAlert(
          "error",
          resp.error.data.message || "there Is A error try again"
        );
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Add Users | BrightSwipe</title>
        </MetaTags>
        <Container fluid>
          <div className=" py-2 pb-3">
            <Link to="/users" className="text-gray text-hover-gray">
              <i className="bx bx-arrow-back"></i> Back to all users
            </Link>
          </div>
        </Container>
        <Container fluid>
          <Card>
            <CardBody>
              <div className="mb-4 h4 text-gray card-title">Add User</div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCehckTwoError();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col sm="4" className="mb-3">
                    <Label className="form-label">Phone Number</Label>
                    <Input
                      name="mobile_without_code"
                      className="form-control"
                      placeholder="Enter phone number"
                      type="text"
                      min={0}
                      value={fieldData.mobile_without_code || ""}
                      onBlur={() => {
                        if (
                          fieldData.mobile_without_code == "" ||
                          fieldData.mobile_without_code == null
                        ) {
                          if (
                            fieldData.email == "" ||
                            fieldData.email == null
                          ) {
                            setErrorShow({
                              ...errorShow,
                              noum: true,
                              noumt: "Please Enter Email or Phone No",
                            });
                          }
                        } else if (
                          fieldData?.mobile_without_code?.length < 10
                        ) {
                          setErrorShow({
                            ...errorShow,
                            noum: true,
                            noumt: "Phone No. Must Be a 10 Digit No",
                          });
                        } else {
                          setErrorShow({
                            ...errorShow,
                            noum: false,
                            noumt: "",
                          });
                        }
                      }}
                      onChange={(e) => {
                        var numbers = /^\d*\d*$/;
                        if (
                          e.target.value.match(numbers) &&
                          e.target.value.length <= 10
                        ) {
                          setFieldData({
                            ...fieldData,
                            mobile_without_code: e.target.value,
                          });
                        }
                        if (
                          fieldData.mobile_without_code == "" ||
                          fieldData.mobile_without_code == null
                        ) {
                          if (
                            fieldData.email == "" ||
                            fieldData.email == null
                          ) {
                            setErrorShow({
                              ...errorShow,
                              noum: true,
                              noumt: "Please Enter Email or Phone No",
                            });
                          }
                        } else if (fieldData?.mobile_without_code?.length < 9) {
                          setErrorShow({
                            ...errorShow,
                            noum: true,
                            noumt: "Phone No. Must Be a 10 Digit No",
                          });
                        } else {
                          setErrorShow({
                            ...errorShow,
                            noum: false,
                            noumt: "",
                            email: false,
                            emailt: "",
                          });
                        }
                      }}
                      invalid={
                        errorShow.noum && errorShow.noum
                          ? true
                          : false
                      }
                    />
                    {errorShow.noum && (
                      <span type="invalid" style={styles}>
                        {errorShow.noumt}
                      </span>
                    )}
                  </Col>
                  <Col sm="4" className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email address"
                      type="text"
                      value={fieldData.email || ""}
                      onBlur={() => {
                        var regexp = /.+@.+\.[A-Za-z]+$/;
                        if (fieldData.email == "" || fieldData.email == null) {
                          if (
                            fieldData.mobile_without_code == "" ||
                            fieldData.mobile_without_code == null
                          ) {
                            setErrorShow({
                              ...errorShow,
                              email: true,
                              emailt: "Please Enter Email or Phone No",
                            });
                          }
                        } else if (!regexp.test(fieldData.email)) {
                          setErrorShow({
                            ...errorShow,
                            email: true,
                            emailt: "Please Enter Valid email",
                            noum: false,
                            noumt: "",
                          });
                        } else {
                          setErrorShow({
                            ...errorShow,
                            noum: false,
                            noumt: "",
                            email: false,
                            emailt: "",
                          });
                        }
                      }}
                      onChange={(e) => {
                        setFieldData({
                          ...fieldData,
                          email: e.target.value,
                        });
                        setErrorShow({
                          ...errorShow,
                          email: false,
                          emailt: " ",
                        });
                      }}

                      invalid={
                        errorShow.email && errorShow.email
                          ? true
                          : false
                      }

                    />
                    {errorShow.email && (
                      <span type="invalid" style={styles}>
                        {errorShow.emailt}
                      </span>
                    )}
                  </Col>

                  <Col sm="4" className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Select
                      name="status"
                      styles={
                        validation.touched.status && validation.errors.status
                          ? customStyles
                          : {}
                      }
                      value={selectedGroup.status || null}
                      onChange={(e) => {
                        validation.setFieldValue("status", e.value);
                        validation.handleChange;
                        setselectedGroup({
                          ...selectedGroup,
                          status: e,
                        });
                      }}
                      options={[
                        { label: "Active", value: "active" },
                        { label: "Suspended", value: "suspended" },
                        { label: "Archived", value: "archived" },
                      ]}
                      classNamePrefix="select2-selection"
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.status && validation.errors.status
                          ? true
                          : false
                      }
                    />
                    {validation.touched.status && validation.errors.status ? (
                      <span type="invalid" style={styles}>
                        {validation.errors.status}
                      </span>
                    ) : null}
                  </Col>
                  <Col sm="4" className="mb-3">
                    <Label className="form-label">Type</Label>
                    <Select
                      styles={
                        validation.touched.customerType &&
                        validation.errors.customerType
                          ? customStyles
                          : {}
                      }
                      name="customerType"
                      value={selectedGroup.type || null}
                      onChange={(e) => {
                        validation.setFieldValue("customerType", e.value);
                        setselectedGroup({
                          ...selectedGroup,
                          type: e,
                        });
                      }}
                      options={[
                        { label: "Pre-customer", value: "pre" },
                        { label: "Post-customer", value: "post" },
                        { label: "Administrator", value: "admin" },
                      ]}
                      classNamePrefix="select2-selection"
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.customerType &&
                        validation.errors.customerType
                          ? true
                          : false
                      }
                    />
                    {validation.touched.customerType &&
                    validation.errors.customerType ? (
                      <span type="invalid" style={styles}>
                        {validation.errors.customerType}
                      </span>
                    ) : null}
                  </Col>

                  <Col sm="4" className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      className="form-control"
                      placeholder="Enter password"
                      type="text"
                      onChange={(e) => {
                        validation.handleChange(e);
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid" style={styles}>
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </Col>

                  <Col sm="16" className="mb-3">
                    <button type="submit" className="btn btn-success me-3">
                      Save Changes
                    </button>
                    <Link to="/users">
                      <button type="button" className="btn btn-secondary">
                        Cancel
                      </button>
                    </Link>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddUsers;
