import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import {Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { CouponListNotifcationApi } from "service/notifactionService";
import moment from "moment";
import linkIcon from '../Notification/linkIcon.png';
import { showAlert } from "components/Common/AlertMessage";

// Added by Parth Roka
const CouponListNotification = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [couponStatusLabel, setCouponStatusLabel] = useState(null);
  const [flage, setFlage] = useState(false);
  const [field, setField] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkmargin, setCheckMargin] = useState("0px");
  const [page, setPage] = useState("1");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);

  useEffect(() => {
    if(props?.flageAPI == true){
     getOrderAll();

    }
    setStartDate(props?.startDate ? moment(props?.startDate).format('YYYY-MM-DD') : "")
    setEndDate( props?.endDate ? moment(props?.endDate).format('YYYY-MM-DD') : "")
    setCouponCode(props?.couponCode)
    setCouponStatus(props?.couponStatus)
    setCouponStatusLabel(props?.couponStatusLabel)
    setFlage(props?.flage)
    setField(props?.field)
  }, [props]);

  useEffect(() => {
      if(field.length == 0  && couponCode.length == 0 && !startDate && !endDate && !couponStatus){
     getOrderAll();
      }
  }, []);

  useEffect(() => {
    if(couponStatusLabel){
      if(couponStatusLabel.label === "Coupon Status"){
        getOrderAll();
      }
    }
    if(field.length > 0 || couponCode.length > 0) {
    getOrderAll();
    }
    if(startDate || endDate || couponStatus){
    getOrderAll();
    }
  }, [startDate , endDate , couponCode , couponStatus , field , couponStatusLabel]);


  useEffect(() => {

    if (currentPage >= 1) {
      getOrderAll();
    }
  }, [currentPage]);

  const getOrderAll = async (pageNo) => {
    setLoading(true)
    const jsonData = {
      "from": startDate ? startDate : "",
      "to": endDate ? endDate : "",
      "coupon_code": couponCode ? couponCode : "",
      "couponStatus": couponStatus ? couponStatus : "",
      "page": currentPage,
      "limit":10
      }
      if(flage == false) {
    const resp = await CouponListNotifcationApi(jsonData);
    if (resp.code == 200) {
      setLoading(false);
      setData(resp.data);
      setTotalPages(resp.totalPages);
    } else {
      setCheckMargin("0px");
      setLoading(false);
    }
      }
  };


  const toggle = () => {
    setModal(!modal);
  };
  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "coupon_code",
      text: "COUPON CODE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">
            {row.coupon_code} / <img title={row.url} src={linkIcon} style={{ height: "15px", cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(row.url).then(() => {
            showAlert("success", "Link Copied To Clipboard ");

          })
            .catch((error) => {
              showAlert("error", "Copy failed!");

            })
          } />
          </p> 
        </>
      ),
    },
    {
      dataField: "created_by",
      text: "CREATED BY",
      sort: true,
      formatter: (cellContent, row) => (
          <>
              <p className="text-gray">
                  {row.created_by}
              </p>
          </>
      ),
  },
    {
      dataField: "createdAt",
      text: "START DATE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>{row?.start_date ? moment(row?.start_date).local().format("MM / DD / YYYY") : "-"}</p>
          {/* <p>{moment(row?.createdAt).local().format("hh:mm:ss a")}</p> */}
        </>
      ),
    },

    {
      dataField: "end_Date",
      text: "END DATE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>{row?.start_date ? moment(row?.end_Date).local().format("MM / DD / YYYY") : "-"}</p>
          {/* <p>{moment(row?.end_Date).local().format("hh:mm:ss a")}</p> */}
        </>
      ),
    },


    {
      dataField: "maximum_usage",
      text: "MAXIMUM USAGE PER USER ",
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">
            <i>{row.maximum_usage || ""}</i>
          </p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      formatter: (cellContent, row) => (
        <span
          className={`font-size-12`}
          style={{
            borderRadius: "10px",
            padding: "5px 10px",
            fontWeight: "bold",
            backgroundColor: row.status === "active" ? "#30e0a1" : row.status === "expired" ? "#ff7373" : "#ffdc82",
            color: "#262626",
          }}
        >
          {row.status}
        </span>
      ),
    }
  ];
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const endPage = Math.min(startPage + 4, totalPages);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <>
            {!loading ? (
              <>
                <React.Fragment>
                  <Row >
                    <Col xl="12">
                      <div className="table-responsive orders-table-main-div" id="harshu">
                        <BootstrapTable
                          keyField="id"
                          data={data}
                          columns={EcommerceOrderColumns(toggle)}
                          responsive
                          bordered={false}
                          striped={false}
                          classes={
                            "table align-middle table-nowrap table-no-check table-check"
                          }
                          headerWrapperClasses={"table-light"}

                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
                {totalPages > 1?   
                data && data.length > 0 ?
                  <div className="pagination pagination-rounded justify-content-center mt-4">
                    <button onClick={handlePreviousLastPage} disabled={currentPage === 1} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                      {'<<'}
                    </button>
                    <button onClick={handlePreviousPage} disabled={currentPage === 1} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                      {'<'}
                    </button>
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(
                      (page) => (
                        <button
                          key={page}
                          onClick={() => handlePageClick(page)}
                          disabled={currentPage === page}
                          className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn btn-light  btn-xl rounded-circle m-2'}
                        >
                          {page}
                        </button>
                      )
                    )}
                    {totalPages > startPage + 4}
                    <button onClick={handleNextPage} disabled={currentPage === totalPages} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                      {'>'}
                    </button>
                    <button onClick={handleLastPage} disabled={currentPage === totalPages} className={currentPage === page ? 'test btn btn-primary btn-circle btn-xl rounded-circle m-2' : 'test btn  btn-circle btn-xl rounded-circle m-2'}>
                      {'>>'}
                    </button>
                  </div>
                  : null
                  : null}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: checkmargin,
                }}
              >
                <Spinner
                  style={{ width: "2rem", height: "2rem" }}
                  children={false}
                />
              </div>
            )}
          </>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

CouponListNotification.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(CouponListNotification);
