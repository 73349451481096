import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {  PaginationProvider} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Col, Row } from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";
import Select from "react-select";

//redux
import { useSelector } from "react-redux";
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal";

const UserEmailNotification = (props) => {
  const [notiData, setUserNoti] = useState([]);

  useEffect(() => {
    setUserNoti(props?.notification);
  }, [props]);

  const selectRow = {
    mode: "none",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: notiData?.length, // replace later with size(orders),
    custom: true,
  };

  // useEffect(() => {
  //   dispatch(onGetOrders())
  // }, [dispatch]);

  const optionGroup = [
    { label: "Active", value: "Active" },
    { label: "Enable", value: "Enable" },
    { label: "Disable", value: "Disable" },
  ];

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const { SearchBar } = Search;

  // const toggleModal = () => {
  //   setModal1(!modal1)
  // }
  const toggleViewModal = () => setModal1(!modal1);

  const EcommerceOrderColumns = (toggleModal) => [
    {
      dataField: "text",
      text: "WHEN",
      sort: true,
      formatter: (cellContent, row) => <>{row.text}</>,
    },
    {
      dataField: "sgTemplate",
      text: "SEND THE SELECTED EMAIL TEMPLATE",
      sort: true,
      formatter: (cellContent, row, rowIndex) => (
        <>
          <Select
            value={row.value}
            menuPortalTarget={document.body}
            onChange={(e) => {
              props.handleUpdatedSelect(e, row, "userEmail");
            }}
            options={row.option}
            classNamePrefix="select2-selection"
            menuPlacement={
              rowIndex == 0 || rowIndex < notiData.length - 1 ? "bottom" : "top"
            }
          />
        </>
      ),
    },
    {
      dataField: "status",
      isDummyField: true,
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div
          className="form-check form-switch d-flex justify-content-end form-switch-lg mb-3"
          onClick={() => {
            props.handleUpdatedCheck(!row.status, row._id, "userEmail");
          }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsize"
            checked={row.status ? true : false}
            // onChange={(e) => {
            //   // props.handleUpdatedCheck(e.target.checked, row._id, "userEmail");
            // }}
          />
          <label
            className={
              row.status ? "form-check-label checked" : "form-check-label"
            }
            htmlFor="customSwitchsize"
          >
            {row.status == true ? "ON" : "OFF"}
          </label>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   if (orders && !orders.length) {
  //     onGetOrders()
  //   }
  // }, [onGetOrders, orders])

  // useEffect(() => {
  //   setOrderList(orders)
  // }, [orders])

  // useEffect(() => {
  //   if (!isEmpty(orders) && !!isEdit) {
  //     setOrderList(orders)
  //     setIsEdit(false)
  //   }
  // }, [orders])

  const toggle = () => {
    setModal(!modal);
  };

  const toLowerCase1 = (str) => {
    return str.toLowerCase();
  };

  const defaultSorted = [
    {
      dataField: "_Id",
      // order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={EcommerceOrderColumns(toggle)}
        data={notiData || []}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            data={notiData || []}
            columns={EcommerceOrderColumns(toggle)}
            bootstrap4
            search
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={selectRow}
                        classes={
                          "table align-middle table-nowrap tableNotificationNew table-no-check table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="pagination pagination-rounded justify-content-center">
                </div>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  );
};

UserEmailNotification.propTypes = {
  notifaction: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(UserEmailNotification);
