import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import {
  Spinner,
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
// action
import {
  userForgetPassword,
  userForgetPasswordError,
} from "../../store/actions";
// import images
import logo from "assets/images/logo/bright-swap-logo.svg";

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
      .required("Please Enter Your Email")
      .email("Please enter a valid email address"),
    }),
    onSubmit: (values) => {
      const allValues = {
        type: "email",
        value: values.email,
        match: "forgetPwd",
      };
      dispatch(userForgetPassword(allValues, props.history));
    },
  });


  const { forgetError, loading } = useSelector((state) => state.ForgetPassword);

  useEffect(() => {
    dispatch(userForgetPasswordError(null));
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title> Forgot Password | BrightSwipe </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="py-5">
                    <Link to="/" className="auth-logo-light">
                      <img
                        src={logo}
                        alt="Bright Swap"
                        // className="rounded-circle"
                        weight="191"
                      />
                    </Link>
                  </div>
                  <div className="py-2">
                    <h5 className="text-primary font-weight-700 text-uppercase letter-spacing-1">
                      RESET YOUR PASSWORD
                    </h5>
                    <p className="mb-0 text-gray">Enter your email below. </p>
                    <p className="text-gray">
                      We will send you a validation code.
                    </p>
                  </div>

                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "7px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label mb-1">E-mail</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter your e-mail"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {loading ? (
                            <button
                              className="btn btn-primary w-md w-100"
                              type="submit"
                              disabled
                            >
                              <Spinner size="sm">Loading...</Spinner>
                              <span> Loading</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary w-md w-100"
                              type="submit"
                            >
                              Reset Password
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                    <div className="mt-5 mb-2 text-left">
                      <Link to="/login" className="text-muted font-weight-500">
                        {/* <input
                          checked="true"
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customControlInline"
                        /> */}
                        <BiArrowBack
                          size={21}
                          style={{
                            marginRight: "7px",
                          }}
                        />
                        Back to Login
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
