import { axiosApi } from "helpers/auth-token";

const postOtp = async data => {
  try {
    const responce = await axiosApi.post(`/users/authenticate`, data)
    return responce.data
  } catch (err) {
    throw err.response.data
  }
}

// /users/authenticate

const postLogin = async data => {
  try {
    const responce = await axiosApi.post(`/admin/user/init`, data)
    return responce.data
  } catch (err) {
    throw err.response.data
  }
}
const postForgetPwd = async data => {
  try {
    const responce = await axiosApi.post(`/users/initiate/forgot`, data)
    return responce.data
 
  } catch (err) {
    throw err.response.data.message
  }
}

const postForgetVerify = async data => {
  try {
    const responce = await axiosApi.post(`/users/verify/forgot/token`, data)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}

const putRecoverPwd = async password => {
  const token =localStorage.getItem("token");
  axiosApi.defaults.headers.common["Authorization"] =token;
  try {
    const responce = await axiosApi.put(`/users/update/password`,password)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}

const putResetPwd = async data => {

  try {
    const responce = await axiosApi.put(`/users/reset/password`, data)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}

const getSingleUser = async (id) => {
  try {
    const responce = await axiosApi.get(`/users/${id}`)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}
const getProfile = async () => {
  try {
    const responce = await axiosApi.get(`/users/fetch/profile`)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}


const deleteUser = async (id) => {
  try {
    const responce = await axiosApi.delete(`/admin/user/delete-user/${id}`)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}


const patchUpdateUser = async (user) => {
  const id=user.id
  try {
    const responce = await axiosApi.patch(`/admin/user/update-user/${id}`,user)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}

const getAllUsers = async (filter) => {
  try {
    const responce = await axiosApi.get(`/admin/user/get-all`)
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}
const updateAdminUser = async (data ,_id) => {
  let token = localStorage.getItem("token");
  try {
    const responce = await axiosApi.put(`/admin/user/profile/${_id}`,data,{
      headers: { Authorization: token },
    })
    return responce.data
  } catch (err) {
    throw err.response.data.message
  }
}
export {
  postLogin,
  postForgetPwd,
  getAllUsers,
  postForgetVerify,
  deleteUser,
  putRecoverPwd,
  putResetPwd,
  patchUpdateUser,
  getSingleUser,
  getProfile,
  updateAdminUser,
  postOtp
}
