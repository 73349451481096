import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Form,
  Label,
  Input,
} from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";
import ViewICon from "./../../assets/icons/view-icon.svg";
import Select from "react-select";
import { getOrderResultAllData } from "../../service/orderResultService";
//redux
import {
  orderServiceGroup,
  orderStatusGroup,
} from "components/Common/DropdownGroup";
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "pages/Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import moment from "moment";
import { Spinner } from "reactstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { DebounceInput } from "react-debounce-input";
import Multiselect from "multiselect-react-dropdown";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const ResultTable = (props) => {
  const dispatch = useDispatch();
  const [selectedGroup, setselectedGroup] = useState(null);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [backUpdata, setBackUpdata] = useState([]);
  const [orderfilter, setOrderfilter] = useState({});
  const [saveDelId, setSaveDelUId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fliedSearcg, setFliedSearch] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderStatusTwo, setOrderStatusTwo] = useState("");
  const [randomNo, setRandomNo] = useState("");
  const [serviecName, setServiceName] = useState(null);
  const [orderId, setOrderId] = useState();
  const [cus_name, setCusName] = useState();
  const [transactionId, settransactionId] = useState();
  const [valueDatePicker, setValueDatePicker] = useState([null, null]);
  const [serviecNameOnly, setServiceNameOnly] = useState([]);
  const [optionData, setOptionData] = useState([
    {
      label: "Anti-Catfish Check",
      value: "Anti-Catfish",
    },
    { label: "Criminal Check", value: "Criminal" },
    { label: "Social Check", value: "Social" },
  ]);
  const [checkmargin, setCheckMargin] = useState("165px");

  const { orders } = useSelector((state) => ({
    orders: state.ecommerce.orders,
  }));

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  useEffect(() => {
    dispatch(onGetOrders());
  }, [dispatch]);

  const selectRow = {
    mode: "none",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orderData.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const toggleViewModal = () => setModal1(!modal1);

  const handleOrderCheck = (type, data) => {
    if (data.order[0].service_name?.toLowerCase().includes("anti-cashfish")) {
    }
    return <ImCross />;
  };

  const EcommerceOrderColumns = (toggleModal) => [
    {
      //payment_status

      dataField: "first_name",
      text: "FIRST NAME",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p> {row.first_name || ""}</p>
            {row.first_name && row.payment_status == "success" && (
              <i
                className="bx bxs-check-circle svg-black me-1 ms-1"
                style={{
                  color: "#30E0A1",
                  fontSize: "18px",
                }}
              ></i>
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "last_name",
      text: "LAST NAME",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p> {row.last_name || ""}</p>
            {row.last_name && row.payment_status == "success" && (
              <i
                className="bx bxs-check-circle svg-black me-1 ms-1"
                style={{
                  color: "#30E0A1",
                  fontSize: "18px",
                }}
              ></i>
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "timesearch",
      text: "TIMES SEARCHED",
      sort: true,
    },
    {
      dataField: "updatedAt",
      text: "LAST CHECKED ON",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p>{moment(row.updatedAt).format("MM/DD/YYYY")}</p>
          <p>{moment(row.updatedAt).format("hh:mm:ss A")}</p>
        </>
      ),
    },
    {
      dataField: "service.name",
      text: "SERVICE",
      // sort: true,
      formatter: (cellContent, row) => (
        <>
          {row?.order?.map((w, i) => {
            let checklength = row?.order?.length;
            return (
              <span>
                {w?.service_name || ""}
                {i >= 0 && i + 1 < checklength && " - "}
              </span>
            );
          })}
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "ACTION",
      formatter: (cellContent, row) => (
        <div className="text-center">
          <Link
            to={`/search-results/details/${row._id}`}
            type="button"
            color="primary"
            className="btn-sm ms-0 btn-icon btn-rounded"
          >
            <img src={ViewICon} alt="view icon" />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (orders && !orders.length) {
      onGetOrders();
    }
  }, [onGetOrders, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    setModal(!modal);
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  useEffect(async () => {
    setLoading(true);
    let chackDat = calculateDateIso();
    const resp = await getOrderResultAllData(chackDat);
    if (resp.code == 200) {
      setBackUpdata(resp.data);
      // setOrderData(resp.data);

      handleApiFetchdata(resp.data);
    } else {
      setLoading(false);
      setCheckMargin("0px");
    }
  }, [
    orderfilter,
    cus_name,
    orderId,
    transactionId,
    orderStatusTwo,
    serviecNameOnly?.length,
  ]);
  const handleApiFetchdata = (data) => {
    let arr = data;
    let dataCheck = localStorage.getItem("search_filter") || {};
    var checkFilter = null;
    if (Object.keys(dataCheck)?.length > 0) {
      checkFilter = JSON?.parse(dataCheck);
    } else {
      checkFilter = dataCheck;
    }
    if (checkFilter?.cus_name) {
      let dataone = arr?.filter((w) => {
        return (
          w?.first_name
            ?.toLowerCase()
            .includes(checkFilter?.cus_name?.toLowerCase()) ||
          w?.last_name
            ?.toLowerCase()
            .includes(checkFilter?.cus_name?.toLowerCase()) ||
          w?.phone
            ?.toLowerCase()
            .includes(checkFilter?.cus_name?.toLowerCase()) ||
          w?.email
            ?.toLowerCase()
            .includes(checkFilter?.cus_name?.toLowerCase()) ||
          w?.user_id
            ?.toLowerCase()
            .includes(checkFilter?.cus_name?.toLowerCase())
        );
      });
      arr = dataone;
    }

    if (checkFilter?.orderId) {
      let datatwo = arr?.filter((w) => {
        return w._id.includes(checkFilter?.orderId);
      });
      arr = datatwo;
    }

    if (
      checkFilter?.serviecNameOnly &&
      checkFilter?.serviecNameOnly?.length > 0
    ) {
      let checkdetails = checkFilter?.serviecNameOnly?.map((w) => w.value);
      let datafour = [];
      arr.map((w) => {
        let arrCheck = null;

        if (
          checkdetails[0] &&
          checkdetails?.length == 1 &&
          w.order.length == 1
        ) {
          if (
            w?.order[0]?.service_name
              ?.toLowerCase()
              .includes(checkdetails[0].toLowerCase())
          ) {
            arrCheck = w;
          }
        }

        if (
          checkdetails[1] &&
          checkdetails?.length == 2 &&
          w.order.length == 2
        ) {
          if (
            (w?.order[0]?.service_name
              ?.toLowerCase()
              .includes(checkdetails[0].toLowerCase()) &&
              w?.order[1]?.service_name
                ?.toLowerCase()
                .includes(checkdetails[1].toLowerCase())) ||
            (w?.order[0]?.service_name
              ?.toLowerCase()
              .includes(checkdetails[1].toLowerCase()) &&
              w?.order[1]?.service_name
                ?.toLowerCase()
                .includes(checkdetails[0].toLowerCase()))
          ) {
            arrCheck = w;
          }
        }
        if (
          checkdetails[2] &&
          checkdetails?.length == 3 &&
          w.order.length == 3
        ) {
          arrCheck = w;
        }
        if (arrCheck !== null) {
          datafour.push(arrCheck);
        }
      });

      arr = datafour;
    }

    if (
      checkFilter?.orderStatusTwo &&
      checkFilter?.orderStatusTwo?.value !== "all Statuses"
    ) {
      let dataThree = arr?.filter((w) => {
        return (
          w?.order[0]?.order_status
            ?.toLowerCase()
            ?.includes(checkFilter?.orderStatusTwo?.value?.toLowerCase()) ||
          w?.order[1]?.order_status
            ?.toLowerCase()
            ?.includes(checkFilter?.orderStatusTwo?.value?.toLowerCase()) ||
          w?.order[2]?.order_status
            ?.toLowerCase()
            ?.includes(checkFilter?.orderStatusTwo?.value?.toLowerCase())
        );
      });
      arr = dataThree;
    }
    if (transactionId) {
      let dataThree = arr?.filter((w) => {
        return w?.transaction_id?.includes(transactionId);
      });
      arr = dataThree;
    }

    setOrderData(arr);
    setLoading(false);
    setCheckMargin("0px");
  };

  useEffect(() => {
    setOrderStatus({ label: "All Statuses", value: "all Statuses" });
    setOrderStatusTwo("all Statuses");
    handleSetLocalFilterItem();
  }, []);

  const calculateDateIso = () => {
    let dataCheck = localStorage.getItem("search_filter") || {};
    var checkFilter = null;
    if (Object.keys(dataCheck)?.length > 0) {
      checkFilter = JSON?.parse(dataCheck);
    } else {
      checkFilter = dataCheck;
    }
    if (checkFilter?.orderfilter && checkFilter.orderfilter?.length > 0) {
      const one = new Date(checkFilter.orderfilter[0]).toISOString();
      const two = new Date(checkFilter.orderfilter[1]).toISOString();
      return {
        to: one,
        from: two,
      };
    } else {
      return {};
    }
  };

  const handleSetLocalFilterItem = () => {
    let dataCheck = localStorage.getItem("search_filter") || {};
    var checkFilter = null;
    if (Object.keys(dataCheck)?.length > 0) {
      checkFilter = JSON?.parse(dataCheck);
    } else {
      checkFilter = dataCheck;
    }

    if (checkFilter?.orderfilter && checkFilter.orderfilter?.length > 0) {
      setValueDatePicker(checkFilter?.orderfilter);
      const one = new Date(checkFilter.orderfilter[0]).toISOString();
      const two = new Date(checkFilter.orderfilter[1]).toISOString();
      setOrderfilter({
        ...orderfilter,
        to: one,
        from: two,
      });
    }
    if (checkFilter?.cus_name) {
      setCusName(checkFilter?.cus_name);
    }
    if (checkFilter?.orderId) {
      setOrderId(checkFilter.orderId);
    }
    if (checkFilter?.transactionId) {
      settransactionId(checkFilter?.transactionId);
    }
    if (
      checkFilter?.orderStatusTwo &&
      Object.keys(checkFilter?.orderStatusTwo)?.length > 0
    ) {
      setOrderStatus(checkFilter.orderStatusTwo);
      setOrderStatusTwo(checkFilter.orderStatusTwo.value);
    }
    if (
      checkFilter?.serviecNameOnly &&
      checkFilter?.serviecNameOnly?.length > 0
    ) {
      setServiceNameOnly(checkFilter?.serviecNameOnly);
    }
  };

  const handlehSetFilter = (name, data) => {
    let dataCheck = localStorage.getItem("search_filter") || "{}";
    var checkFilter = JSON?.parse(dataCheck);

    let makeSetData = {
      orderfilter: checkFilter?.orderfilter || {},
      orderfiltert: checkFilter?.orderfiltert || {},
      cus_name: checkFilter?.cus_name || "",
      orderId: checkFilter?.orderId || "",
      transactionId: checkFilter?.transactionId || "",
      orderStatusTwo: checkFilter?.orderStatusTwo || {
        label: "All Statuses",
        value: "all Statuses",
      },
      serviecNameOnly: checkFilter?.serviecNameOnly || [],
    };
    makeSetData[name] = data;
    localStorage.setItem("search_filter", JSON.stringify(makeSetData));
  };

  // useEffect(() => {
  //   let arr = backUpdata;
  //   if (cus_name) {
  //     let dataone = arr?.filter((w) => {
  //       return (
  //         w?.first_name?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.last_name?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.phone?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.email?.toLowerCase().includes(cus_name?.toLowerCase()) ||
  //         w?.user_id?.toLowerCase().includes(cus_name?.toLowerCase())
  //       );
  //     });
  //     arr = dataone;
  //   }

  //   if (orderId) {
  //     let datatwo = arr?.filter((w) => {
  //       return w._id.includes(orderId);
  //     });
  //     arr = datatwo;
  //   }

  //   if (transactionId) {
  //     let dataThree = arr?.filter((w) => {
  //       return w?.transaction_id?.includes(transactionId);
  //     });
  //     arr = dataThree;
  //   }
  //   if (serviecNameOnly) {
  //     let dataFour = arr?.filter((w) => {
  //       //service_name
  //       return (
  //         w?.order[0]?.service_name
  //           ?.toLowerCase()
  //           .includes(serviecNameOnly.toLowerCase()) ||
  //         w?.order[1]?.service_name
  //           ?.toLowerCase()
  //           .includes(serviecNameOnly.toLowerCase()) ||
  //         w?.order[2]?.service_name
  //           ?.toLowerCase()
  //           .includes(serviecNameOnly.toLowerCase())
  //       );
  //     });
  //     arr = dataFour;
  //   }

  //   if (orderStatusTwo) {
  //     let dataThree = arr?.filter((w) => {
  //       return (
  //         w?.order[0]?.order_status
  //           ?.toLowerCase()
  //           ?.includes(orderStatusTwo.toLowerCase()) ||
  //         w?.order[1]?.order_status
  //           ?.toLowerCase()
  //           ?.includes(orderStatusTwo.toLowerCase()) ||
  //         w?.order[2]?.order_status
  //           ?.toLowerCase()
  //           ?.includes(orderStatusTwo.toLowerCase())
  //       );
  //     });
  //     arr = dataThree;
  //   }
  //   setOrderData(arr);
  // }, [cus_name, orderId, transactionId, orderStatusTwo, serviecNameOnly]);

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <div className="p-2">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            // validation.handleSubmit();
            return false;
          }}
        >
          <div className="row">
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Order ID</Label>
              {/* <Input
                name="orderID"
                value={orderId || ""}
                className="form-control field-search"
                placeholder="Type Order Id"
                type="search"
                onChange={(e) => {
                  setOrderId(e.target.value);
                }}
              /> */}

              <DebounceInput
                debounceTimeout={300}
                minLength={2}
                name="orderID"
                value={orderId || ""}
                className="form-control field-search"
                placeholder="Type Order Id"
                onChange={(e) => {
                  handlehSetFilter("orderId", e.target.value);
                  setOrderId(e.target.value);
                }}
              />
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Order Status</Label>
              <Select
                placeholder={
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    All Statuses
                  </span>
                }
                value={orderStatus || null}
                onChange={(e) => {
                  setOrderStatus(e);
                  setOrderStatusTwo(e.value);
                  handlehSetFilter("orderStatusTwo", e);
                }}
                options={[
                  { label: "All Statuses", value: "all Statuses" },
                  { label: "Verified", value: "verified" },
                  { label: "Partial", value: "partial" },
                  { label: "Pending", value: "pending" },
                  { label: "No match", value: "no match" },
                ]}
                classNamePrefix="select2-selection"
              />
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Date Range</Label>
              {/* <Input
                name="filterDate"
                className="form-control"
                placeholder="Type Something"
                type="date"
                onChange={(e) => {
                  const d = new Date(e.target.value);
                  let text = d.toISOString();
                  setOrderfilter({
                    ...orderfilter,
                    dateRange: text,
                  });
                }}
              /> */}

              <DateRangePicker
                onChange={(w) => {
                  setValueDatePicker(w);
                  const one = w[0].toISOString();
                  const two = w[1].toISOString();
                  setOrderfilter({
                    ...orderfilter,
                    to: one,
                    from: two,
                  });
                  let fild = {
                    to: one,
                    from: two,
                  };
                  handlehSetFilter("orderfilter", w);
                  handlehSetFilter("orderfiltert", fild);
                }}
                value={valueDatePicker}
                maxDate={new Date()}
                rangeDivider=" - "
                format="dd / MM / yyyy "
                clearIcon={null}
                className="datePicker2"
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
              />
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Customer</Label>
              {/* <Input
                name="filterCustomer"
                value={cus_name || ""}
                className="form-control field-search"
                placeholder="By Customer Name "
                type="search"
                onChange={(e) => {
                  setCusName(e.target.value);
                }}
              /> */}

              <DebounceInput
                debounceTimeout={300}
                minLength={2}
                name="filterCustomer"
                value={cus_name || ""}
                className="form-control field-search"
                placeholder="By Customer Name "
                type="search"
                onChange={(e) => {
                  setCusName(e.target.value);
                  handlehSetFilter("cus_name", e.target.value);
                }}
              />
            </div>
            <div className="col-4 mb-3 multiSelect2">
              <Label className="form-label">Filter by Service</Label>
              <ReactMultiSelectCheckboxes
                options={optionData}
                value={serviecNameOnly}
                onChange={(e) => {
                  setServiceNameOnly(e);
                  handlehSetFilter("serviecNameOnly", e);

                  setCheckMargin("165px");
                }}
                onFocus={() => {
                  setCheckMargin("165px");
                }}
                onBlur={() => {
                  setCheckMargin("0px");
                }}
              />
              {/* <Multiselect
                options={optionData}
                selectedValues={optionData}
                displayValue="label"
                showCheckbox={true}
                onSelect={(e) => {
                  setRandomNo(Math.random());
                  setServiceNameOnly(e);
                }}
                onRemove={(e) => {
                  setRandomNo(Math.random());
                  setServiceNameOnly(e);
                }}
              /> */}
            </div>
            <div className="col-4 mb-3">
              <Label className="form-label">Filter by Transaction ID</Label>
              {/* <Input
                name="transactionID"
                value={transactionId}
                className="form-control field-search"
                placeholder="Type Something"
                type="search"
                onChange={(e) => {
                  settransactionId(e.target.value);
                }}
              /> */}
              <DebounceInput
                debounceTimeout={300}
                minLength={2}
                name="transactionID"
                value={transactionId}
                className="form-control field-search"
                placeholder="Type Something"
                type="search"
                onChange={(e) => {
                  settransactionId(e.target.value);
                  handlehSetFilter("transactionId", e.target.value);
                }}
              />
            </div>
          </div>
        </Form>
      </div>
      <Card>
        <CardBody>
          {orderData?.length == 0 && !loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                marginTop: checkmargin,
              }}
            >
              <h2>No Data Found</h2>
            </div>
          ) : (
            <>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: checkmargin,
                  }}
                >
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                </div>
              ) : (
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  //  keyField="name"
                  // columns={EcommerceOrderColumns(toggle)}
                  // data={orderData || []}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="name"
                      data={orderData || []}
                      columns={EcommerceOrderColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="name"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-no-check table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="pagination pagination-rounded justify-content-center mt-4">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

ResultTable.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(ResultTable);
