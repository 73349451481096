import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import {
  typeGroup,
  statusGroup,
  roleGroup,
} from "../../components/Common/DropdownGroup";
import { useDispatch } from "react-redux";
// import { updateUser } from "store/users/actions";

import { updateUser, getUserAllData } from "../../service/userService";
import Swal from "sweetalert2";
import { showAlert } from "components/Common/AlertMessage";
const UserModal = (props) => {
  const { isOpen, toggle, editValue } = props;
  const dispatch = useDispatch();

  const inputValue = {
    email: "",
    mobile_phone: "",
  };
  const [value, setValue] = useState(inputValue);
  const [role, setRole] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setValue(editValue);
    setRole({
      label: capitalizeFirstLetter(editValue.role || ""),
      value: editValue.role,
    });
    setStatus({
      label: capitalizeFirstLetter(editValue.status || ""),
      value: editValue.status,
    });
    setType({
      label: capitalizeFirstLetter(editValue.customerType || ""),
      value: editValue.status,
    });
  }, [editValue]);

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { first_name, last_name, email, mobile_phone, _id } = value;

  const handleChangeGroup = (e, sts) => {
    if (sts == "status") {
      var sStatus = e || status;
      setStatus(sStatus);
    } else if (sts == "type") {
      var sType = e || type;
      setType(sType);
    } else {
      var sRole = e || role;
      setRole(sRole);
    }
  };

  const updateHandler = async () => {
    const objValue = {
      email: value.email,
      mobile_phone: value.mobile_phone,
      status: status.value,
      role: role.value,
      customerType: type.value,
    };
    props.setLoading(true);
    const resp = await updateUser(_id, objValue);
    if (resp.code == 200) {
      const resp2 = await getUserAllData(props.userfilter);
      if (resp2.code == 200) {
        const data = resp.data.users.filter(
          (user) => user.status == "active" || user.status == "suspended"
        );
        // setUserData(resp2.data.users)
        showAlert("success", "User Updated ");
        props.HandleSetUserData(data);
      }
    } else {
      showAlert(
        "error",
        resp.error.data.message || "there Is A error try again"
      );
      props.setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Update User</ModalHeader>
        <ModalBody>
          <Form
            className="form-horizontal"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   validation.handleSubmit();
            //   return false;
            // }}
          >
            {/* <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">First Name</Label>
                <Input
                  name="first_name"
                  className="form-control"
                  placeholder="Enter First Name"
                  value={first_name}
                  onChange={onChangeHandle}
                  type="text"
                />
              </Col>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Last Name</Label>
                <Input
                  name="last_name"
                  value={last_name}
                  className="form-control"
                  placeholder="Enter Last Name"
                  onChange={onChangeHandle}
                  type="text"
                />
              </Col>
            </Row> */}
            <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Phone Number</Label>
                <Input
                  name="mobile_phone"
                  className="form-control"
                  placeholder="Enter phone number"
                  value={mobile_phone}
                  onChange={onChangeHandle}
                  type="text"
                />
              </Col>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Enter email address"
                  onChange={onChangeHandle}
                  value={email}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Status</Label>
                <Select
                  name="status"
                  value={status}
                  onChange={(e) => {
                    handleChangeGroup(e, "status");
                  }}
                  options={[
                    { label: "Active", value: "active" },
                    { label: "Suspended", value: "suspended" },
                    { label: "Archived", value: "archived" },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Type</Label>
                <Select
                  name="type"
                  value={type}
                  onChange={(e) => {
                    handleChangeGroup(e, "type");
                  }}
                  options={[
                    { label: "Pre-customer", value: "pre" },
                    { label: "Post-customer", value: "post" },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Role</Label>
                <Select
                  name="role"
                  value={role}
                  onChange={(e) => {
                    handleChangeGroup(e, "role");
                  }}
                  options={roleGroup}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={updateHandler}>
            Save Changes
          </Button>
          <Button type="button" color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

UserModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  editValue: PropTypes.func,
};

export default UserModal;
