import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Swal from "sweetalert2";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

import { postForgetPwd, postForgetVerify, putRecoverPwd } from "helpers/apis";
import { apiError } from "../login/actions";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (user.match == "forgetPwd") {
      const response = yield call(postForgetPwd, user);
      if (response) {
        const message = {
          user: user,
          data: response.data,
          message: "An otp has been sent to your registered email",
        };
        localStorage.setItem("verfyEmial", JSON.stringify(message));

        yield put(userForgetPasswordSuccess(message));
        history.push("/verify-email");
      }
    } else if (user.match == "verifyEmail") {
      const response = yield call(postForgetVerify, user);
      if (response) {
        const message = {
          accessToken: response.accessToken,
          message: "user Email or Phone verify successfully ! ",
        };
        yield put(userForgetPasswordSuccess(message));
        localStorage.setItem("token", response.accessToken);
        history.push("/reset-password");
      }
    } else if (user.match == "resetPwd") {
      const response = yield call(putRecoverPwd, user);
      if (response) {
        const message = {
          message:
            "your Password Changed successfully please login with changed Password!",
        };
        handleSWal("success","Your password updated successfully")
        yield put(userForgetPasswordSuccess(message));
        yield put(apiError(null));
        history.push("/login");
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

const handleSWal = (icons, message) => {
  Swal.fire({
    toast: true,
    icon: icons,
    title: message,
    animation: false,
    position: "top-right",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
