import React, { useEffect, useState } from "react";

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Card, CardBody, Col, Row,Spinner
  } from "reactstrap";
  import BootstrapTable from "react-bootstrap-table-next";

function CouponLogsModel(props) {
    const { isOpen, toggle  } = props;

    const [data, setData] = useState([]);

    const myArray = [
        { id: 1, name: 'Item 1' },
        { id: 2, name: 'Item 2' },
        { id: 3, name: 'Item 3' }
      ];


    useState(() => {
setData(myArray)
    },[])


    const EcommerceOrderColumns = (toggleModal) => [
        {
            dataField: "coupon_code",
            text: "COUPON CODE",
            sort: true,
            formatter: (cellContent, row) => {
                return (
                <>
                <p className="text-gray">
                    fddfggdvdsgfsdgsd
                    </p>
                </>
            );
        }
    },
    ];

  return (
    <Modal
    isOpen={isOpen}
    role="dialog"
    autoFocus={true}
    centered={true}
    className="exampleModal"
    tabIndex="-1"
    toggle={() => toggle(false)}
  >
    <div className="modal-content">
      <ModalHeader toggle={() => toggle(false)}>dfvds</ModalHeader>
      <ModalBody>
      <React.Fragment>
                                    <Row >
                                        <Col xl="12">
                                            <div className="table-responsive orders-table-main-div" id="harshu">
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={data}
                                                    columns={EcommerceOrderColumns()}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    classes={
                                                        "table align-middle table-nowrap table-no-check table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}

                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" onClick={() => toggle(false)}>
          Close
        </Button>
      </ModalFooter>
    </div>
  </Modal>
  )
}

export default CouponLogsModel
