import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
const OrderModal = (props) => {
  const { isOpen, toggle, editValue, updateHandler } = props;
  const inputValue = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    age: "",
    state: "",
    city: "",
    payment_status: "",
  };
  const [value, setValue] = useState(inputValue);
  const [status, setStatus] = useState(null);
  const [paystatus, setPayStatus] = useState("");

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    const editData = editValue || "";
    setValue(editData);
    setPayStatus(editData.payment_status);
    setStatus({
      label: capitalizeFirstLetter(editData.payment_status || ""),
      value: editData.payment_status,
    });
  }, [editValue]);

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const editedData = () => {
    value.payment_status = paystatus;
    updateHandler(value);
  };

  const {
    first_name,
    last_name,
    email,
    phone,
    age,
    state,
    city,
    payment_status,
  } = value;
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Update Order</ModalHeader>
        <ModalBody>
          <Form
            className="form-horizontal"
          >
            <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">First Name</Label>
                <Input
                  name="first_name"
                  className="form-control"
                  placeholder="Enter First Name"
                  value={first_name}
                  onChange={onChangeHandle}
                  type="text"
                />
              </Col>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Last Name</Label>
                <Input
                  name="last_name"
                  value={last_name}
                  className="form-control"
                  placeholder="Enter Last Name"
                  onChange={onChangeHandle}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Phone Number</Label>
                <Input
                  name="phone"
                  className="form-control"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={onChangeHandle}
                  type="text"
                />
              </Col>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Enter email address"
                  onChange={onChangeHandle}
                  value={email}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">City</Label>
                <Input
                  name="city"
                  className="form-control"
                  placeholder="Enter Your City"
                  onChange={onChangeHandle}
                  value={city}
                  type="text"
                />
              </Col>
              <Col sm="6" className="mb-3">
                <Label className="form-label">State</Label>
                <Input
                  name="state"
                  className="form-control"
                  placeholder="Enter Your State"
                  onChange={onChangeHandle}
                  value={state}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Age</Label>
                <Input
                  name="age"
                  className="form-control"
                  placeholder="Enter Your age"
                  onChange={onChangeHandle}
                  value={age}
                  type="text"
                />
              </Col>
              <Col sm="6" className="mb-3">
                <Label className="form-label">Payment Status </Label>
                <Select
                  name="payment_status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e);
                    setPayStatus(e.value);
                  }}
                  options={[
                    { label: "Success", value: "success" },
                    { label: "Failed", value: "failed" },
                    { label: "Pending", value: "pending" },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={editedData}>
            Save Changes
          </Button>
          <Button type="button" color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

OrderModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  editValue: PropTypes.func,
};

export default OrderModal;
