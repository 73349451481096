import React, { useRef, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {Card, CardBody, Col, Container, Form, Input,Label, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { uplodeImage, addPage } from "../../service/pageService";
import { useHistory } from "react-router-dom";
import { showAlert } from "components/Common/AlertMessage";
import { RichTextEditor } from "@mantine/rte";

const AddContentManagement = () => {
  const history = useHistory();
  const pic =
    "https://media.istockphoto.com/vectors/no-photo-available-vector-icon-default-image-symbol-picture-coming-vector-id1354776450?s=612x612";
  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [imageObject, setImageObject] = useState(null);
  const [data, setData] = useState({
    title: "",
    slug: "",
    image: "",
  });
  const [contentValue, setChangeContent] = useState("");
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = function () {
      setImage(reader.result);
    };
    reader.readAsDataURL(fileObj);
    if (!fileObj) {
      return;
    }
    setImageObject(fileObj);
  };

  const HandleInputField = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmitChange = async () => {
    let value = {
      title: data.title,
      slug: data.slug,
      image: data.image,
      content: contentValue,
    };
    let img = null;
    if (imageObject) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", imageObject);
      let imageurl = await uplodeImage(bodyFormData);
      img = imageurl.data.path;
    }
    if (img) {
      value.image = img;
    }
    const resp = await addPage(value);
    if (resp.code == 200) {
      history.push("/content-manager");
      showAlert("success", "Content page created successfully");
    } else {
      showAlert(
        "error",
        resp.error.response.data.message || "There Is a error try again"
      );
    }
  };

  const { title, slug } = data;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Add Content Management | BrightSwipe </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Content Management" />
        </Container>
        <Container fluid>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();

                  return false;
                }}
              >
                <Row>
                  <Col sm="4" className="mb-3">
                    <Label className="form-label">Headline</Label>
                    <Input
                      value={title}
                      name="title"
                      className="form-control"
                      placeholder="Enter title"
                      type="text"
                      onChange={HandleInputField}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="8" className="mb-3">
                    <div className="textarea-content border-bottom-gray2 pb-4">
                      <Label className="form-label">Content</Label>
                      <RichTextEditor
                        id="rte"
                        value={contentValue}
                        name="content"
                        placeholder="Enter content"
                        type="textarea"
                        onChange={(e) => {
                          setChangeContent(e);
                        }}
                      />
                    </div>
                    <div className="form-action pt-3">
                      <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={handleSubmitChange}
                      >
                        Add Slide
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          history.push("/content-manager");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col sm="4" className="mb-3">
                    <div className="profile-picture24 ">
                      <img
                        src={image == "" ? pic : image}
                        alt="picture new"
                        className="img-fluid w-100"
                      />
                    </div>
                    <div className="field-group mt-4">
                      <Label className="form-label">About us image</Label>
                      <Input
                        value={slug}
                        name="slug"
                        className="form-control"
                        placeholder="uploaded image name"
                        type="text"
                        onChange={HandleInputField}
                      />
                    </div>
                    <div className="upload-profile d-flex align-items-start mt-3">
                      <input
                        style={{ display: "none" }}
                        max-file-size="2048"
                        ref={inputRef}
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleFileChange}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary me-3"
                        onClick={handleClick}
                      >
                        Upload
                      </button>
                      <p className="text-gray">
                        Accepted formats: JPG, PNG Your image must be up to
                        512x512px and cannot be over 2MB.{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddContentManagement;
