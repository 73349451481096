import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { CreditUsageAPI } from "service/notifactionService";
import moment from "moment";
import linkIcon from "../Notification/linkIcon.png";
import { showAlert } from "components/Common/AlertMessage";
import { decryptData } from "Encryption/encryption";
// import { decryptDataCheck } from "Encryption/encryptionCheck";

// Added by Moinuddin Mansuri
const CreditUsageLogs = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [couponStatusLabel, setCouponStatusLabel] = useState(null);
  const [flage, setFlage] = useState(false);
  const [field, setField] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkmargin, setCheckMargin] = useState("0px");
  const [page, setPage] = useState("1");
  const [data, setData] = useState([]);
  console.log("data", data)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);

  useEffect(() => {
    if (props?.flageAPI == true) {
      getOrderAll();
    }
    setStartDate(props?.startDate ? moment(props?.startDate).format("YYYY-MM-DD") : "");
    setEndDate(props?.endDate ? moment(props?.endDate).format("YYYY-MM-DD") : "");
    setCouponCode(props?.couponCode);
    setCouponStatus(props?.couponStatus);
        setCouponStatusLabel(props?.couponStatusLabel);
    setFlage(props?.flage);
    setField(props?.field);
  }, [props]);

  useEffect(() => {
    getOrderAllSecond();
    if (field.length == 0 && couponCode.length == 0 && !startDate && !endDate && !couponStatus) {
      getOrderAll();
    }
  }, []);

  const getOrderAllSecond = async (pageNo) => {
    setLoading(true)
    const jsonData = {
      from: startDate ? startDate : "",
      to: endDate ? endDate : "",
      coupon_code: couponCode ? couponCode : "",
      couponStatus: couponStatus ? couponStatus : "",
      page: currentPage,
      limit: 10,
    };
    const resp = await CreditUsageAPI(jsonData);
    if (resp.code == 200) {
      setLoading(false)
      setData(resp.data);
      setTotalPages(resp.totalPages);
    } else {
      setLoading(false)
      setCheckMargin("0px");
      setData([]);
    }
  };

  // console.log("data", data);

  useEffect(() => {
    if (couponStatusLabel) {
      if (couponStatusLabel.label === "Coupon Status") {
        getOrderAll();
      }
    }
    if (field.length > 0 || couponCode.length > 0) {
      getOrderAll();
    }
    if (startDate || endDate || couponStatus) {
      getOrderAll();
    }
  }, [startDate, endDate, couponCode, couponStatus, field, couponStatusLabel]);

  useEffect(() => {
    if (currentPage >= 1) {
      getOrderAll();
    }
  }, [currentPage]);

  const getOrderAll = async (pageNo) => {
    setLoading(true)
    const jsonData = {
      from: startDate ? startDate : "",
      to: endDate ? endDate : "",
      coupon_code: couponCode ? couponCode : "",
      couponStatus: couponStatus ? couponStatus : "",
      page: currentPage,
      limit: 10,
    };
    if (flage == true) {
      const resp = await CreditUsageAPI(jsonData);
      console.log("respo", resp);
      if (resp.code == 200) {
        setLoading(false)
        setData(resp.data);
        setTotalPages(resp.totalPages);
      } else {
        setLoading(false)
        setCheckMargin("0px");
        setData([]);
      }
    }
  };

  const toggle = () => {
    setModal(!modal);
  };
  const EcommerceOrderColumns = (toggleModal) => [
    // {
    //   dataField: "coupon_code",
    //   text: "COUPON CODE",
    //   sort: true,
    //   formatter: (cellContent, row) => (
    //     <>
    //       <p className="text-gray">
    //         {row.coupon_code} / <img title={row.url} src={linkIcon} style={{ height: "15px", cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(row.url).then(() => {
    //         showAlert("success", "Link Copied To Clipboard ");

    //       })
    //         .catch((error) => {
    //           showAlert("error", "Copy failed!");

    //         })
    //       } />
    //       </p>
    //     </>
    //   ),
    // },
    //   {
    //     dataField: "created_by",
    //     text: "CREATED BY",
    //     sort: true,
    //     formatter: (cellContent, row) => (
    //         <>
    //             <p className="text-gray">
    //                 {row.created_by}
    //             </p>
    //         </>
    //     ),
    // },
    {
      dataField: "createdAt",
      text: "DATE",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">{moment(row?.createdAt).local().format("MM / DD / YYYY")}</p>
        </>
      ),
    },

    {
      dataField: "user_details",
      text: "USER DETAILS",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {/* <p>{row?.user_detail?.email !== "" ? row?.user_detail?.email : row?.user_detail?.mobile_phone !== "" ? row?.user_detail?.mobile_phone : "No Data"}</p>     */}

          <p>{ row?.user_detail?.email ? row?.user_detail?.email : ""  }</p>   

          <p>{ row?.user_detail?.mobile_phone ? row?.user_detail?.mobile_phone : ""  }</p>   

        </>
      ),
    },

    {
      dataField: "service_name",
      text: "SERVICES",
      formatter: (cellContent, row) => {
        // let maping = row?.order_detail ? row?.order_detail.map((i) => i) : ""
        // console.log("maping", maping)
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="text-gray">
                {row?.order_detail
                  ? row?.order_detail.map((order, orderIndex) => (
                      <h6 key={orderIndex}>
                        {order.service_name}
                        {orderIndex < row?.order_detail.length - 1  && ","  }
                      </h6>
                    ))
                  : ""}
              </p>
            </div>
          </>
        );
      },
    },

    //added by mansi
    {
      dataField: "service_name",
      text: "COUPUN CODE",

      formatter: (cellContent, row) => {
        console.log("maping", row);

        // const newData= row?.order_detail ? row?.coupon_details.map((e)=>e.secret_code)
        // const newData = JSON.parse(decryptData(row?.secret_code))
        // console.log("newData",newData);
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="text-gray">
                {console.log(row, "roww")}
                {row?.order_detail
                  ? row?.coupon_details.map((order, orderIndex) => (
                      <h6 key={orderIndex}>
                        {/* {console.log("HII",order.secret_code)} */ JSON.parse(decryptData(order.secret_code)).coupon_code}
                        {/* {orderIndex < row?.order_detail.length - 1 && ","} */}
                      </h6>
                    ))
                  : ""}
              </p>
            </div>
          </>
        );
      },
    },

    {
      dataField: "device_type",
      text: "DEVICE",
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray" style={{ textTransform: "uppercase" }}>
            {row?.device_type || "-"}
            {/* <i>{row?.coupon_detail?.maximum_usage ? row?.coupon_detail?.maximum_usage : "" }</i> */}
            {/* <i>{row.coupon_detail.mobile_phone || ""}</i> */}
          </p>
        </>
      ),
    },
  ];
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () => {    
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const endPage = Math.min(startPage + 4, totalPages);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <>
            {!loading ? (
              <>
                <React.Fragment>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive orders-table-main-div" id="harshu">
                        <BootstrapTable keyField="id" data={data} columns={EcommerceOrderColumns(toggle)} responsive bordered={false} striped={false} classes={"table align-middle table-nowrap table-no-check table-check"} headerWrapperClasses={"table-light"} />
                        </div>
                    </Col>
                  </Row>
                </React.Fragment>
                {totalPages > 1 ? (
                  data && data.length > 0 ? (
                    <div className="pagination pagination-rounded justify-content-center mt-4 ">
                      <button onClick={handlePreviousLastPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {"<<"}
                      </button>
                      <button onClick={handlePreviousPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {"<"}
                      </button>
                      {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
                        <button key={page} onClick={() => handlePageClick(page)} disabled={currentPage === page} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn btn-light  btn-xl rounded-circle m-2"}>
                          {page}
                        </button>
                      ))}
                      {totalPages > startPage + 4}
                      <button onClick={handleNextPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {">"}
                      </button>
                      <button onClick={handleLastPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {">>"}
                      </button>
                    </div>
                  ) : null
                ) : null}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: checkmargin,
                }}
              >
                <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
              </div>
            )}
          </>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

CreditUsageLogs.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(CreditUsageLogs);
