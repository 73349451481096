import {
 UPDATE_USER,
 UPDATE_USER_FAILED,
 UPDATE_USER_SUCCESSFUL,
 DELETE_USER,
 DELETE_USER_SUCCESSFUL,
 DELETE_USER_FAILED,
 GETUSER,
 USERLOADINGTRUE,

 TYPEUSER
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  userloading:false,
  typeuser: null,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case TYPEUSER:
      state = {
        ...state,
        // userloading: false,
        typeuser: action.payload,
      }
      break
    case USERLOADINGTRUE:
      state = {
        ...state,
        userloading: true,
       
      }
      break
    case GETUSER:
      state = {
        ...state,
        userloading: false,
        user: action.payload,
      }
      break
    case UPDATE_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case UPDATE_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      }
      break

    case UPDATE_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
      case DELETE_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case DELETE_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      }
      break
    case DELETE_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
