export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESSFUL = "edit_user_successfull"
export const UPDATE_USER_FAILED = "edit_user_failed"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESSFUL = "delete_user_successfull"
export const DELETE_USER_FAILED = "delete_user_failed"
export const GETUSER = "GETUSER"
export const USERLOADINGTRUE = "USERLOADINGTRUE"
export const USERLOADINGFALSE = "USERLOADINGFALSE"

export const TYPEUSER = "TYPEUSER"


