import {
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESSFUL,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESSFUL,
  GETUSER,
  USERLOADINGTRUE,

  TYPEUSER
} from "./actionTypes"

export const typeUser = user => {
  return {
    type: TYPEUSER,
    payload:  user ,
  }
}

export const userloadingtrue = user => {
  return {
    type: USERLOADINGTRUE,
  }
}


export const getUserData = (data) => ({
  type: GETUSER,
  payload: data
});


export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload:  user ,
  }
}

export const updateUserSuccessful = user => {
  return {
    type: UPDATE_USER_SUCCESSFUL,
    payload: user,
  }
}

export const updateUserFailed = user => {
  return {
    type: UPDATE_USER_FAILED,
    payload: user,
  }
}


export const deleteUser = user => {
  return {
    type: DELETE_USER,
    payload:  user ,
  }
}

export const deleteUserSuccessful = user => {
  return {
    type: DELETE_USER_SUCCESSFUL,
    payload: user,
  }
}

export const deleteUserFailed = user => {
  return {
    type: DELETE_USER_FAILED,
    payload: user,
  }
}



