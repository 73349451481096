import axios from "axios";

export const getAllserviceCount = async (data) => {
  let token = localStorage.getItem("token");
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/user/service-count`,
      {
        headers: { Authorization: token },
      }
    );
    if (resp.data.code == 200) {
      return resp.data;
    } else {
      throw new Error(resp);
    }
  } catch (err) {
    return {
      data: "",
      error: err,
      status: 400,
    };
  }
};
