import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import {
  typeGroup,
  statusGroup,
  roleGroup,
} from "../../components/Common/DropdownGroup";
import { useDispatch } from "react-redux";
// import { updateUser } from "store/users/actions";
import { resetPassword } from "../../service/userService";

import Swal from "sweetalert2";
const UserModal = (props) => {
  const { isOpen, toggle, id } = props;
  const [password, setpassword] = useState("");
  const [confirmpsd, setComfirmPsd] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errortect, setErorortext] = useState("");
  const [errorShowpsd, setErrorShowpsd] = useState(false);
  const [errortectpsd, setErorortextpsd] = useState("");




  const handleCancle = () => {
toggle();
  setpassword("");
  setComfirmPsd("");
  setErorortext("");
  setErorortextpsd("");

  }

  const handleSubmit = async () => {
    let checksatus = true;
    if (password == "") {
      checksatus = false;
      setErrorShow(true);
      setErorortext("Please Enter New Password");
    } else if (password.length < 6) {
      checksatus = false;
      setErrorShow(true);
      setErorortext("Password Should be more than 5 digits");
    } else {
      setErrorShow(false);
      setErorortext("");
    }
    if (confirmpsd == "") {
      checksatus = false;
      setErrorShowpsd(true);
      setErorortextpsd("Please Enter Confirm  Password");
    } else if (confirmpsd !== password) {
      checksatus = false;
      setErrorShowpsd(true);
      setErorortextpsd("New Password and Confirm Password Must be Same");
    } else {
      setErrorShowpsd(false);
      setErorortextpsd("");
    }
    let data = {
      password: password,
    };

    if (checksatus) {
      const resp = await resetPassword(id, data);
      if (resp.code == 200) {
        handleSWal("success", resp.massage);
        toggle();
      } else {
        handleSWal(
          "error",
          resp.error.data.message || "there Is A error try again"
        );
      }
    }
  };

  const handleSWal = (icons, message) => {
    Swal.fire({
      toast: true,
      icon: icons,
      title: message,
      animation: false,
      position: "top-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      showCloseButton: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={handleCancle}>Reset User Password</ModalHeader>
        <ModalBody>
          <Form
            className="form-horizontal"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   validation.handleSubmit();
            //   return false;
            // }}
          >
            <Row>
              <Col sm="12" className="mb-3">
                <Label className="form-label">New Password</Label>
                <Input
                  name="password"
                  className="form-control"
                  placeholder="Enter New Password "
                  value={password}
                  onBlur={() => {
                    if (password == "") {
                      setErrorShow(true);
                      setErorortext("Please Enter New Password");
                    } else if (password.length < 8) {
                      setErrorShow(true);
                      setErorortext("Password Should be more than 5 digits");
                    } else {
                      setErrorShow(false);
                      setErorortext("");
                    }
                  }}
                  onChange={(e) => {
                    setpassword(e.target.value);

                    if (password == "") {
                      setErrorShow(true);
                      setErorortext("Please Enter New Password");
                    } else if (password.length < 8) {
                      setErrorShow(true);
                      setErorortext("Password Should be more than 5 digits");
                    } else {
                      setErrorShow(false);
                      setErorortext("");
                    }
                  }}
                  type="text"
                />
              </Col>
              {errorShow && (
                <Row>
                  <Col sm="12" className="mb-3">
                    <Label
                      className="form-label"
                      style={{
                        color: "red",
                      }}
                    >
                      {errortect}
                    </Label>
                  </Col>
                </Row>
              )}
              <Col sm="12" className="mb-3">
                <Label className="form-label">Confirm Password</Label>
                <Input
                  name="password"
                  className="form-control"
                  placeholder="Enter Confirm  Password "
                  onBlur={() => {
                    if (confirmpsd == "") {
                      setErrorShowpsd(true);
                      setErorortextpsd("Please Enter Confirm  Password");
                    } else if (confirmpsd !== password) {
                      setErrorShowpsd(true);
                      setErorortextpsd(
                        "New Password and Confirm Password Must be Same "
                      );
                    } else {
                      setErrorShowpsd(false);
                      setErorortextpsd("");
                    }
                  }}
                  value={confirmpsd}
                  onChange={(e) => {
                    setComfirmPsd(e.target.value);

                    if (confirmpsd == "") {
                      setErrorShowpsd(true);
                      setErorortextpsd("Please Enter Confirm  Password");
                    } else if (confirmpsd !== password) {
                      setErrorShowpsd(true);
                      setErorortextpsd(
                        "New Password and Confirm Password Must be Same "
                      );
                    } else {
                      setErrorShowpsd(false);
                      setErorortextpsd("");
                    }
                  }}
                  type="text"
                />
              </Col>
            </Row>
            {errorShowpsd && (
              <Row>
                <Col sm="12" className="mb-3">
                  <Label
                    className="form-label"
                    style={{
                      color: "red",
                    }}
                  >
                    {errortectpsd}
                  </Label>
                </Col>
              </Row>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => handleSubmit()}
          >
            Save Changes
          </Button>
          <Button type="button" color="secondary" onClick={handleCancle}>
            Cancel
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

UserModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  editValue: PropTypes.func,
};

export default UserModal;
